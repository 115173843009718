import * as types from './types'

const initialState = {
  notificationList: {
    data: [],
    meta: {}
  }
}

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS:
    case types.APPEND_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList: action.payload
      }
    case types.GET_NOTIFICATIONS_ASYNC:
    case types.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        ...initialState
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}
