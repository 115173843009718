function delay() {
  var promise = new Promise(((resolve) => {
    window.setTimeout(() => {
      resolve('done!')
    }, 250)
  }))
  return promise
}

export function jwtMiddleware() {
  return next => (action) => {
    if (action && action.type && action.type === 'HIDE_LOADING') {
      return delay().then(() => next(action))
    }
    return next(action)
  }
}
