import * as types from './types'

const initialState = {
  partnerList: {
    data: [],
    meta: {}
  },
  partnerDetail: {},
  receivedRequestList: {
    data: [],
    meta: {}
  },
  sentRequestList: {
    data: [],
    meta: {}
  },
  publicPartnerList: {
    data: [],
    meta: {}
  },
  lastUpdated: new Date().getTime()
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case types.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerList: action.payload
      }
    // case types.GET_PARTNERS_ASYNC:
    case types.GET_PARTNERS_FAIL:
      return {
        ...state,
        partnerList: {
          data: [],
          meta: {}
        }
      }

    case types.GET_PUBLIC_PARTNER_SUCCESS:
      return {
        ...state,
        publicPartnerList: action.payload,
        lastUpdated: new Date().getTime()
      }
    case types.GET_PUBLIC_PARTNER_FAIL:
      return {
        ...state,
        publicPartnerList: {
          data: [],
          meta: {}
        }
      }
    case types.GET_PARTNER_DETAIL_ASYNC:
    case types.GET_PARTNER_DETAIL_FAIL:
      return {
        ...state,
        partnerDetail: {}
      }
    case types.GET_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: action.payload
      }

    case types.GET_RECEIVED_REQUEST_SUCCESS:
      return {
        ...state,
        receivedRequestList: action.payload
      }
    case types.GET_RECEIVED_REQUEST_FAIL:
      return {
        ...state,
        receivedRequestList: {
          data: [],
          meta: {}
        }
      }
    case types.GET_SENT_REQUEST_SUCCESS:
      return {
        ...state,
        sentRequestList: action.payload
      }
    case types.GET_SENT_REQUEST_FAIL:
      return {
        ...state,
        sentRequestList: {
          data: [],
          meta: {}
        }
      }

    default:
      return state
  }
}
