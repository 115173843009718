import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

class PrivateRoute extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    routes: PropTypes.array,
    authed: PropTypes.bool
  }

  render() {
    const {
      component: Component, authed, ...rest
    } = this.props
    return [
      <Route
        {...rest}
        key="privateRoute"
        render={(props) => {
          if (authed === true) {
            return <Component {...props} />
          }
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
        }
      />]
  }
}
PrivateRoute.propTypes = {
}

export default PrivateRoute
