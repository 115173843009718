import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class Loading extends Component {
  static propTypes = {
    commonReducer: PropTypes.object
  }

  render() {
    const { commonReducer, forceShowLoading } = this.props
    if (forceShowLoading) {
      return (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(255, 255, 255)',
            zIndex: 1051,
            top: 0
          }}
        >
          <div
            className="m-loader m-loader--lg"
            style={{
              width: 30, position: 'absolute', top: 0, bottom: 0, marginLeft: '50%'
            }}
          />
        </div>
      )
    }
    if (!commonReducer.isShowLoading) {
      return null
    }
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(255, 255, 255)',
          zIndex: 1051,
          top: 0
        }}
      >
        <div
          className="m-loader m-loader--lg"
          style={{
            width: 30, position: 'absolute', top: 0, bottom: 0, marginLeft: '50%'
          }}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    commonReducer: state.commonReducer
  }
}

export default connect(mapStateToProps)(Loading)
