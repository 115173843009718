export const GET_REQUEST_TRACKER_ASYNC = 'GET_REQUEST_TRACKER_ASYNC'
export const GET_REQUEST_TRACKER_SUCCESS = 'GET_REQUEST_TRACKER_SUCCESS'
export const GET_REQUEST_TRACKER_FAIL = 'GET_REQUEST_TRACKER_FAIL'

export const APPEND_REQUEST_TRACKER_ASYNC = 'APPEND_REQUEST_TRACKER_ASYNC'
export const APPEND_REQUEST_TRACKER_SUCCESS = 'APPEND_REQUEST_TRACKER_SUCCESS'
export const APPEND_REQUEST_TRACKER_FAIL = 'APPEND_REQUEST_TRACKER_FAIL'

export const REMOVE_REQUEST_TRACKER_ASYNC = 'REMOVE_REQUEST_TRACKER_ASYNC'
export const REMOVE_REQUEST_TRACKER_SUCCESS = 'REMOVE_REQUEST_TRACKER_SUCCESS'
export const REMOVE_REQUEST_TRACKER_FAIL = 'REMOVE_REQUEST_TRACKER_FAIL'
