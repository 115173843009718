import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'
import { objectToParams } from '../../helpers/utils'

function* getPatientAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }

    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/customers?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_PATIENTS_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_PATIENTS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_PATIENTS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getPatientDetailAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/customers/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_PATIENT_DETAIL_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_PATIENT_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_PATIENT_DETAIL_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createPatientAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/customers`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_PATIENT_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* editPatientAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/customers/${action.id}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        }
        break
      }
      default: {
        yield [
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.EDIT_PATIENT_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}
function* uploadFile(action) {
  try {
    const { data, meta } = yield call(request.uploadFile, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers?action=import_list_patient&data[override]=${action.override}&data[defineBy]=${action.defineBy}&data[updateFrom]=${action.updateFrom}&data[updateTo]=${action.updateTo}`, action.payload.params)
    if (action.callback) {
      action.callback(true, data, meta)
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.GET_PATIENTS_ASYNC, getPatientAsync),
    takeLatest(types.CREATE_PATIENT_ASYNC, createPatientAsync),
    takeLatest(types.EDIT_PATIENT_ASYNC, editPatientAsync),
    takeEvery(types.GET_PATIENT_DETAIL_ASYNC, getPatientDetailAsync),
    takeLatest(types.IMPORT_PATIENT_ASYNC, uploadFile)
  ])
}
