import * as types from './type'

const initialState = {
  listCaseAutomation: {
    data: [],
    meta: {}
  }
}

export default function caseAutomationReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_CASE_AUTOMATION_SUCCESS:
      return {
        ...state,
        listCaseAutomation: action.payload
      }
    // case types.GET_LIST_CASE_ASYNC:
    case types.GET_LIST_CASE_AUTOMATION_FAIL:
      return {
        ...state,
        listCaseAutomation: {
          data: [],
          meta: {}
        }
      }
    default:
      return state
  }
}
