export const GET_TRACK_REQUEST_ASYNC = 'GET_TRACK_REQUEST_ASYNC'
export const GET_TRACK_REQUEST_SUCCESS = 'GET_TRACK_REQUEST_SUCCESS'
export const GET_TRACK_REQUEST_FAIL = 'GET_TRACK_REQUEST_FAIL'

export const GET_TRACK_REQUEST_DETAIL_ASYNC = 'GET_TRACK_REQUEST_DETAIL_ASYNC'
export const GET_TRACK_REQUEST_DETAIL_SUCCESS = 'GET_TRACK_REQUEST_DETAIL_SUCCESS'
export const GET_TRACK_REQUEST_DETAIL_FAIL = 'GET_TRACK_REQUEST_DETAIL_FAIL'

export const CREATE_DISCUSSION_ASYNC = 'CREATE_DISCUSSION_ASYNC'
export const CREATE_DISCUSSION_SUCCESS = 'CREATE_DISCUSSION_SUCCESS'
export const CREATE_DISCUSSION_FAIL = 'CREATE_DISCUSSION_FAIL'

export const APPEND_DISCUSSION_ASYNC = 'APPEND_DISCUSSION_ASYNC'
export const APPEND_DISCUSSION_SUCCESS = 'APPEND_DISCUSSION_SUCCESS'
export const APPEND_DISCUSSION_FAIL = 'APPEND_DISCUSSION_FAIL'

export const GET_DISCUSSION_ASYNC = 'GET_DISCUSSION_ASYNC'
export const GET_DISCUSSION_SUCCESS = 'GET_DISCUSSION_SUCCESS'
export const GET_DISCUSSION_FAIL = 'GET_DISCUSSION_FAIL'

export const UPDATE_TRACK_REQUEST_ASYNC = 'UPDATE_TRACK_REQUEST_ASYNC'
export const UPDATE_TRACK_REQUEST_SUCCESS = 'UPDATE_TRACK_REQUEST_SUCCESS'
export const UPDATE_TRACK_REQUEST_FAIL = 'UPDATE_TRACK_REQUEST_FAIL'

export const GET_LETTER_CONTENT_ASYNC = 'GET_LETTER_CONTENT_ASYNC'
export const GET_LETTER_CONTENT_SUCCESS = 'GET_LETTER_CONTENT_SUCCESS'
export const GET_LETTER_CONTENT_FAIL = 'GET_LETTER_CONTENT_FAIL'

export const CREATE_LETTER_ASYNC = 'CREATE_LETTER_ASYNC'
export const CREATE_LETTER_SUCCESS = 'CREATE_LETTER_SUCCESS'
export const CREATE_LETTER_FAIL = 'CREATE_LETTER_FAIL'

export const GET_ATTACHMENT_CONTENT_ASYNC = 'GET_ATTACHMENT_CONTENT_ASYNC'
export const GET_ATTACHMENT_CONTENT_SUCCESS = 'GET_ATTACHMENT_CONTENT_SUCCESS'
export const GET_ATTACHMENT_CONTENT_FAIL = 'GET_ATTACHMENT_CONTENT_FAIL'

export const GET_TRACK_REQUEST_BY_CASE_ASYNC = 'GET_TRACK_REQUEST_BY_CASE_ASYNC'
export const GET_TRACK_REQUEST_BY_CASE_SUCCESS = 'GET_TRACK_REQUEST_BY_CASE_SUCCESS'
export const GET_TRACK_REQUEST_BY_CASE_FAIL = 'GET_TRACK_REQUEST_BY_CASE_FAIL'

export const GET_TRACK_REQUEST_ATTACHMENTS_ASYNC = 'GET_TRACK_REQUEST_ATTACHMENTS_ASYNC'
export const GET_TRACK_REQUEST_ATTACHMENTS_SUCCESS = 'GET_TRACK_REQUEST_ATTACHMENTS_SUCCESS'
export const GET_TRACK_REQUEST_ATTACHMENTS_FAIL = 'GET_TRACK_REQUEST_ATTACHMENTS_FAIL'

export const UPDATE_TRACK_REQUEST_ATTACHMENTS_ASYNC = 'UPDATE_TRACK_REQUEST_ATTACHMENTS_ASYNC'
export const UPDATE_TRACK_REQUEST_ATTACHMENTS_SUCCESS = 'UPDATE_TRACK_REQUEST_ATTACHMENTS_SUCCESS'
export const UPDATE_TRACK_REQUEST_ATTACHMENTS_FAIL = 'UPDATE_TRACK_REQUEST_ATTACHMENTS_FAIL'

export const CREATE_ORDER_ASYNC = 'CREATE_ORDER_ASYNC'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL'

export const UPDATE_ORDER_ASYNC = 'UPDATE_ORDER_ASYNC'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'

export const DELETE_ORDER_ASYNC = 'DELETE_ORDER_ASYNC'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL'

export const GET_RELATED_REQUEST_ASYNC = 'GET_RELATED_REQUEST_ASYNC'
export const GET_RELATED_REQUEST_SUCCESS = 'GET_RELATED_REQUEST_SUCCESS'
export const GET_RELATED_REQUEST_FAIL = 'GET_RELATED_REQUEST_FAIL'

export const ADD_RELATED_REQUEST_ASYNC = 'ADD_RELATED_REQUEST_ASYNC'
export const ADD_RELATED_REQUEST_SUCCESS = 'ADD_RELATED_REQUEST_SUCCESS'
export const ADD_RELATED_REQUEST_FAIL = 'ADD_RELATED_REQUEST_FAIL'

export const DELETE_RELATED_REQUEST_ASYNC = 'DELETE_RELATED_REQUEST_ASYNC'
export const DELETE_RELATED_REQUEST_SUCCESS = 'DELETE_RELATED_REQUEST_SUCCESS'
export const DELETE_RELATED_REQUEST_FAIL = 'DELETE_RELATED_REQUEST_FAIL'

export const GET_HELPER_TEMPLATE_ASYNC = 'GET_HELPER_TEMPLATE_ASYNC'
export const GET_HELPER_TEMPLATE_SUCCESS = 'GET_HELPER_TEMPLATE_SUCCESS'
export const GET_HELPER_TEMPLATE_FAIL = 'GET_HELPER_TEMPLATE_FAIL'

export const GET_TRACK_REQUEST_REPORT_ASYNC = 'GET_TRACK_REQUEST_REPORT_ASYNC'
export const GET_TRACK_REQUEST_REPORT_SUCCESS = 'GET_TRACK_REQUEST_REPORT_SUCCESS'
export const GET_TRACK_REQUEST_REPORT_FAIL = 'GET_TRACK_REQUEST_FAIL'

export const GET_ACTIVITY_REQUEST_ASYNC = 'GET_ACTIVITY_REQUEST_ASYNC'
export const GET_ACTIVITY_REQUEST_SUCCESS = 'GET_ACTIVITY_REQUEST_SUCCESS'
export const GET_ACTIVITY_REQUEST_FAIL = 'GET_ACTIVITY_REQUEST_FAIL'

export const CREATE_ACTIVITY_REQUEST_ASYNC = 'CREATE_ACTIVITY_REQUEST_ASYNC'
export const CREATE_ACTIVITY_REQUEST_SUCCESS = 'CREATE_ACTIVITY_REQUEST_SUCCESS'
export const CREATE_ACTIVITY_REQUEST_FAIL = 'CREATE_ACTIVITY_REQUEST_FAIL'

export const DELETE_ACTIVITY_REQUEST_ASYNC = 'DELETE_ACTIVITY_REQUEST_ASYNC'
export const DELETE_ACTIVITY_REQUEST_SUCCESS = 'DELETE_ACTIVITY_REQUEST_SUCCESS'
export const DELETE_ACTIVITY_REQUEST_FAIL = 'DELETE_ACTIVITY_REQUEST_FAIL'

export const GET_ORDER_HISTORY_ASYNC = 'GET_ORDER_HISTORY_ASYNC'
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS'
export const GET_ORDER_HISTORY_FAIL = 'GET_ORDER_HISTORY_FAIL'

export const UPDATE_SERVICE_CHARGE_ASYNC = 'UPDATE_SERVICE_CHARGE_ASYNC'
export const UPDATE_SERVICE_CHARGE_SUCCESS = 'UPDATE_SERVICE_SUCCESS'
export const UPDATE_SERVICE_CHARGE_FAIL = 'UPDATE_SERVICE_CHARGE_FAIL'

export const CREATE_SERVICE_CHARGE_ASYNC = 'CREATE_SERVICE_CHARGE_ASYNC'
export const CREATE_SERVICE_CHARGE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_CHARGE_FAIL = 'CREATE_SERVICE_CHARGE_FAIL'

export const GET_SERVICE_CHARGE_HISTORY_ASYNC = 'GET_SERVICE_CHARGE_HISTORY_ASYNC'
export const GET_SERVICE_CHARGE_HISTORY_SUCCESS = 'GET_SERVICE_CHARGE_HISTORY_SUCCESS'
export const GET_SERVICE_CHARGE_HISTORY_FAIL = 'GET_SERVICE_CHARGE_HISTORY_FAIL'

export const GET_REQUEST_METADATA = 'GET_REQUEST_METADATA'
export const GET_DATES_REQUEST_SUCCESS = 'GET_DATES_REQUEST_SUCCESS'
export const GET_DATES_REQUEST_FAIL = 'GET_DATES_REQUEST_FAIL'

export const GET_END_DATES_REQUEST_ASYNC = 'GET_END_DATES_REQUEST_ASYNC'
export const GET_END_DATES_REQUEST_SUCCESS = 'GET_END_DATES_REQUEST_SUCCESS'
export const GET_END_DATES_REQUEST_FAIL = 'GET_END_DATES_REQUEST_FAIL'

export const UPDATE_REQUEST_METADATA_ASYNC = 'UPDATE_REQUEST_METADATA_ASYNC'
export const UPDATE_DATES_REQUEST_SUCCESS = 'UPDATE_DATES_REQUEST_SUCCESS'
export const UPDATE_DATES_REQUEST_FAIL = 'UPDATE_DATES_REQUEST_FAIL'

export const UPDATE_RECEIVER_REQUEST_ASYNC = 'UPDATE_RECEIVER_REQUEST_ASYNC'
export const UPDATE_RECEIVER_REQUEST_SUCCESS = 'UPDATE_RECEIVER_REQUEST_SUCCESS'
export const UPDATE_RECEIVER_REQUEST_FAIL = 'UPDATE_RECEIVER_REQUEST_FAIL'

export const GET_RECEIVER_REQUEST_ASYNC = 'GET_RECEIVER_REQUEST_ASYNC'
export const GET_RECEIVER_REQUEST_SUCCESS = 'GET_RECEIVER_REQUEST_SUCCESS'
export const GET_RECEIVER_REQUEST_FAIL = 'GET_RECEIVER_REQUEST_FAIL'

export const CREATE_ASSIGN_REQUEST_ASYNC = 'CREATE_ASSIGN_REQUEST_ASYNC'
export const CREATE_ASSIGN_REQUEST_SUCCESS = 'CREATE_ASSIGN_REQUEST_SUCCESS'
export const CREATE_ASSIGN_REQUEST_FAIL = 'CREATE_ASSIGN_REQUEST_FAIL'

export const DELETE_ASSIGN_REQUEST_ASYNC = 'DELETE_ASSIGN_REQUEST_ASYNC'
export const DELETE_ASSIGN_REQUEST_SUCCESS = 'DELETE_ASSIGN_REQUEST_SUCCESS'
export const DELETE_ASSIGN_REQUEST_FAIL = 'DELETE_ASSIGN_REQUEST_FAIL'

export const DELETE_LETTER_REQUEST_ASYNC = 'DELETE_LETTER_REQUEST_ASYNC'
export const DELETE_LETTER_REQUEST_SUCCESS = 'DELETE_LETTER_REQUEST_SUCCESS'
export const DELETE_LETTER_REQUEST_FAIL = 'DELETE_LETTER_REQUEST_FAIL'

export const GET_TEMPLATE_ASYNC = 'GET_TEMPLATE_ASYNC'
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS'
export const GET_TEMPLATE_FAIL = 'GET_TEMPLATE_FAIL'

export const GET_READINESS_ACTION = 'GET_READINESS_ACTION'
export const GET_READINESS_ACTION_SUCCESS = 'GET_READINESS_ACTION_SUCCESS'
export const GET_READINESS_ACTION_FAIL = 'GET_READINESS_ACTION_FAIL'

export const CREATE_READINESS_ACTION = 'CREATE_READINESS_ACTION'
export const CREATE_READINESS_ACTION_SUCCESS = 'CREATE_READINESS_ACTION_SUCCESS'
export const CREATE_READINESS_ACTION_FAIL = 'CREATE_READINESS_ACTION_FAIL'

export const UPDATE_READINESS_ACTION = 'UPDATE_READINESS_ACTION'
export const UPDATE_READINESS_ACTION_SUCCESS = 'UPDATE_READINESS_ACTION_SUCCESS'
export const UPDATE_READINESS_ACTION_FAIL = 'UPDATE_READINESS_ACTION_FAIL'

export const DELETE_READINESS_ACTION = 'DELETE_READINESS_ACTION'
export const DELETE_READINESS_ACTION_SUCCESS = 'DELETE_READINESS_ACTION_SUCCESS'
export const DELETE_READINESS_ACTION_FAIL = 'DELETE_READINESS_ACTION_FAIL'

export const GET_REQUEST_LOGS_ASYNC = 'GET_REQUEST_LOGS_ASYNC'
export const GET_REQUEST_LOGS_SUCCESS = 'GET_REQUEST_LOGS_SUCCESS'
export const GET_REQUEST_LOGS_FAIL = 'GET_REQUEST_LOGS_FAIL'
