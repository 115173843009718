import * as types from './types'

const initialState = {
  companyInfo: {},
  employees: {
    data: [],
    meta: []
  },
  employeeDetail: {},
  roles: {
    data: [],
    meta: []
  },
  roleDetail: {},
  group: {
    data: [],
    meta: []
  },
  groupDetail: {},
  templateList: {
    data: [],
    meta: []
  },
  memberRoleDetail: {
    data: [],
    meta: []
  },
  memberGroupDetail: {
    data: [],
    meta: []
  },
  stateList: [],
  cityList: [],
  zipCodeList: [],
  requestHashtags: []
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_COMPANY_PROFILE_SUCCESS:
    case types.GET_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companyInfo: action.payload
      }
    case types.GET_COMPANY_PROFILE_ASYNC:
    case types.GET_COMPANY_PROFILE_FAIL:
      return {
        ...state,
        companyInfo: {}
      }

    case types.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload
      }
    case types.GET_MEMBER_ROLE_SUCCESS:
      return {
        ...state,
        memberRoleDetail: action.payload
      }
    case types.GET_MEMBER_GROUP_SUCCESS:
      return {
        ...state,
        memberGroupDetail: action.payload
      }
    case types.GET_EMPLOYEES_DETAIL_SUCCESS:
      return {
        ...state,
        employeeDetail: action.payload
      }
    case types.GET_EMPLOYEES_DETAIL_ASYNC:
    case types.GET_EMPLOYEES_DETAIL_FAIL:
      return {
        ...state,
        employeeDetail: {}
      }
    // case types.GET_EMPLOYEES_ASYNC:
    case types.GET_EMPLOYEES_FAIL:
      return {
        ...state,
        employees: {
          data: [],
          meta: []
        }
      }

    case types.GET_LIST_ROLE_SUCCESS:
      return {
        ...state,
        roles: action.payload
      }
    case types.GET_ROLE_DETAIL_SUCCESS:
      return {
        ...state,
        roleDetail: action.payload
      }
    case types.GET_ROLE_DETAIL_ASYNC:
    case types.GET_ROLE_DETAIL_FAIL:
      return {
        ...state,
        roleDetail: {}
      }
    case types.GET_LIST_ROLE_ASYNC:
      return {
        ...state,
        roles: {
          data: [],
          meta: []
        }
      }

    case types.GET_LIST_GROUP_SUCCESS:
      return {
        ...state,
        group: action.payload
      }
    case types.GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        groupDetail: action.payload
      }
    case types.GET_GROUP_DETAIL_ASYNC:
    case types.GET_GROUP_DETAIL_FAIL:
      return {
        ...state,
        groupDetail: {}
      }

    case types.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateList: action.payload
      }
    case types.GET_TEMPLATE_FAIL:
      return {
        ...state,
        templateList: {
          data: [],
          meta: []
        }
      }

    case types.GET_STATE_SUCCESS:
      return {
        ...state,
        stateList: action.payload
      }

    case types.GET_CITY_BY_STATE_SUCCESS:
      return {
        ...state,
        cityList: action.payload
      }

    case types.GET_ZIP_CODE_BY_STATE_AND_CITY_SUCCESS:
      return {
        ...state,
        zipCodeList: action.payload
      }

    case types.GET_REQUEST_HASHTAGS_SETTING_SUCCESS:
      return {
        ...state,
        requestHashtags: action.payload
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
