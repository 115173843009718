import * as types from './types'

const initialState = {
  manageCaseListDashboard: {
    data: [],
    meta: {}
  },
  requestListDashboard: {
    data: [],
    meta: {}
  }
}

export default function dashBoardReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_CASE_DASHBOARD_SUCCESS:
      return {
        ...state,
        manageCaseListDashboard: action.payload
      }
    // case types.GET_LIST_CASE_ASYNC:
    case types.GET_LIST_CASE_DASHBOARD_FAIL:
      return {
        ...state,
        manageCaseListDashboard: {
          data: [],
          meta: {}
        }
      }

    case types.GET_TRACK_REQUEST_DASHBOARD_SUCCESS:
      return {
        ...state,
        requestListDashboard: action.payload
      }
    // case types.GET_TRACK_REQUEST_ASYNC:
    case types.GET_TRACK_REQUEST_DASHBOARD_FAIL:
      return {
        ...state,
        requestListDashboard: {
          data: [],
          meta: {}
        }
      }

    default:
      return state
  }
}
