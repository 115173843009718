import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkPermissions } from '../../helpers/utils'

class Sidebar extends Component {
  static propTypes = {
    userInfo: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
    }
    this.checkRouter = this.checkRouter.bind(this)
  }

  onClickMenuItem = () => () => {
    const { onToggleLeftSideBar } = this.props
    onToggleLeftSideBar()
  }

  checkRouter(pathName) {
    const { location } = this.props
    if (location.pathname.indexOf(pathName) >= 0) {
      return true
    }
    return false
  }

  render() {
    const { userInfo } = this.props
    return (
    /* <!-- BEGIN: Left Aside --> */

      <div>
        { window.portal === 'pharmacy'
          ? (
            <div id="m_aside_left" className="m-grid__item m-aside-left  m-aside-left--skin-dark pharmacy-bg">
              <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-aside-menu--dropdown">
                <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                  <li className={`m-menu__item ${this.checkRouter('/dashboard') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="dashboard">
                    <NavLink onClick={this.onClickMenuItem('/dashboard')} to="/dashboard" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                      <span className="m-menu__item-here" />
                      <i className="m-menu__link-icon fas fa-chart-bar" />
                      <span className="m-menu__link-text">Dashboard</span>
                      <i className="m-menu__ver-arrow fas fa-angle-right" />
                    </NavLink>
                  </li>
                  {checkPermissions(userInfo.permissions, 'REPORT', 'ACCESS_REPORTS') && (
                    <li className={`m-menu__item ${this.checkRouter('/report') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="report">
                      <NavLink onClick={this.onClickMenuItem('/report')} to="/report" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-chart-line" />
                        <span className="m-menu__link-text">Report</span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'REQUEST', 'MANAGE_REQUEST') && (
                    <li className={`m-menu__item ${this.checkRouter('/submit-request') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="submit">
                      <NavLink onClick={this.onClickMenuItem('/submit-request')} to="/submit-request" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-home" />
                        <span className="m-menu__link-text">Submit Request</span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'REQUEST', 'LIST_ALL_REQUEST') && (
                    <li className={`m-menu__item ${this.checkRouter('/track-request') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="track">
                      <NavLink onClick={this.onClickMenuItem('/track-request')} to="/track-request" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-file-alt" />
                        <span className="m-menu__link-text">Track Request</span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'PATIENT', 'LIST_ALL_PATIENT') && (
                    <li className={`m-menu__item ${this.checkRouter('/patient-management') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="patient">
                      <NavLink onClick={this.onClickMenuItem('/patient-management')} to="/patient-management" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-user-friends" />
                        <span className="m-menu__link-title">
                          <span className="m-menu__link-wrap">
                            <span className="m-menu__link-text">Manage Patient</span>
                          </span>
                        </span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'CASE', 'LIST_ALL_CASE') && (
                    <li className={`m-menu__item ${this.checkRouter('/case-management') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" data-redirect="true">
                      <NavLink onClick={this.onClickMenuItem('/case-management')} to="/case-management" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-layer-group" />
                        <span className="m-menu__link-text">
                Manage Case
                        </span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  <li className={`m-menu__item ${this.checkRouter('/network-management') ? 'm-menu__item--active' : ''} d-none`} data-menu-submenu-toggle="hover" data-redirect="true">
                    <NavLink onClick={this.onClickMenuItem('/network-management')} to="/network-management" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                      <span className="m-menu__item-here" />
                      <i className="m-menu__link-icon fas fa-network-wired" />
                      <span className="m-menu__link-text">
              Manage Network

                      </span>
                      <i className="m-menu__ver-arrow fas fa-angle-right" />
                    </NavLink>
                  </li>
                  <li className={`m-menu__item ${this.checkRouter('/automation') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" data-redirect="true">
                    <NavLink onClick={this.onClickMenuItem('/automation')} to="/automation?welcome=true" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                      <span className="m-menu__item-here" />
                      <i className="m-menu__link-icon fab fa-autoprefixer" />
                      <span className="m-menu__link-text">
                Automation
                      </span>
                      <i className="m-menu__ver-arrow fas fa-angle-right" />
                    </NavLink>
                  </li>
                  {checkPermissions(userInfo.permissions, 'SETTING', 'ACCESS_SETTINGS') && (
                    <li className={`m-menu__item ${this.checkRouter('/settings') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" data-redirect="true">
                      <NavLink onClick={this.onClickMenuItem('/settings')} to="/settings" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-cog" />
                        <span className="m-menu__link-text">
                Settings
                        </span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}

                </ul>
              </div>
            </div>
          )
          : (
            <div id="m_aside_left" className="m-grid__item m-aside-left  m-aside-left--skin-dark attorney-bg">
              <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-aside-menu--dropdown">
                <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                  <li className={`m-menu__item color-hover ${this.checkRouter('/dashboard') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="dashboard">
                    <NavLink onClick={this.onClickMenuItem('/dashboard')} to="/dashboard" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                      <span className="m-menu__item-here" />
                      <i className="m-menu__link-icon fas fa-chart-bar icon-sidebar-attorney" />
                      <span className="m-menu__link-text text-sidebar-attorney">Dashboard</span>
                      <i className="m-menu__ver-arrow fas fa-angle-right" />
                    </NavLink>
                  </li>
                  {checkPermissions(userInfo.permissions, 'REPORT', 'ACCESS_REPORTS') && (
                    <li className={`m-menu__item color-hover ${this.checkRouter('/report') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="report">
                      <NavLink onClick={this.onClickMenuItem('/report')} to="/report" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-chart-line icon-sidebar-attorney" />
                        <span className="m-menu__link-text text-sidebar-attorney">Report</span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'REQUEST', 'MANAGE_REQUEST') && (
                    <li className={`m-menu__item color-hover ${this.checkRouter('/submit-request') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="submit">
                      <NavLink onClick={this.onClickMenuItem('/submit-request')} to="/submit-request" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-home icon-sidebar-attorney" />
                        <span className="m-menu__link-text text-sidebar-attorney">Submit Request</span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'REQUEST', 'LIST_ALL_REQUEST') && (
                    <li className={`m-menu__item color-hover ${this.checkRouter('/track-request') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="track">
                      <NavLink onClick={this.onClickMenuItem('/track-request')} to="/track-request" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-file-alt icon-sidebar-attorney" />
                        <span className="m-menu__link-text text-sidebar-attorney">Track Request</span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'CLIENT', 'LIST_ALL_CLIENT') && (
                    <li className={`m-menu__item color-hover ${this.checkRouter('/client-management') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="client">
                      <NavLink onClick={this.onClickMenuItem('/client-management')} to="/client-management" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-user-friends icon-sidebar-attorney" />
                        <span className="m-menu__link-title">
                          <span className="m-menu__link-wrap">
                            <span className="m-menu__link-text text-sidebar-attorney">Manage Client</span>
                          </span>
                        </span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}
                  {checkPermissions(userInfo.permissions, 'CASE', 'LIST_ALL_CASE') && (
                    <li className={`m-menu__item color-hover ${this.checkRouter('/case-management') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" data-redirect="true">
                      <NavLink onClick={this.onClickMenuItem('/case-management')} to="/case-management" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-layer-group icon-sidebar-attorney" />
                        <span className="m-menu__link-text text-sidebar-attorney">
              Manage Case

                        </span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}

                  <li className={`m-menu__item color-hover ${this.checkRouter('/network-management') ? 'm-menu__item--active' : ''} d-none`} data-menu-submenu-toggle="hover" data-redirect="true">
                    <NavLink onClick={this.onClickMenuItem('/network-management')} to="/network-management" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                      <span className="m-menu__item-here" />
                      <i className="m-menu__link-icon fas fa-network-wired icon-sidebar-attorney" />
                      <span className="m-menu__link-text text-sidebar-attorney">
              Manage Network
                      </span>
                      <i className="m-menu__ver-arrow fas fa-angle-right" />
                    </NavLink>
                  </li>
                  {checkPermissions(userInfo.permissions, 'SETTING', 'ACCESS_SETTINGS') && (
                    <li className={`m-menu__item color-hover ${this.checkRouter('/settings') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" data-redirect="true">
                      <NavLink onClick={this.onClickMenuItem('/settings')} to="/settings" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                        <span className="m-menu__item-here" />
                        <i className="m-menu__link-icon fas fa-cog icon-sidebar-attorney" />
                        <span className="m-menu__link-text text-sidebar-attorney">
              Settings
                        </span>
                        <i className="m-menu__ver-arrow fas fa-angle-right" />
                      </NavLink>
                    </li>
                  )}

                </ul>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
function mapStateToProp(state) {
  return {
    userInfo: state.accountsReducer.userInfo
  }
}

export default connect(mapStateToProp, null)(Sidebar)
