import {
  call, put, takeLatest, all, select, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version, portals
} from '../../api'
import * as commonTypes from '../common/types'
import { getPublicPartners } from './selector'
import { objectToParams } from '../../helpers/utils'

function* getPartnersAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }

    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/partners?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_PARTNERS_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getPartnerDetailAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/partners/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_PARTNER_DETAIL_SUCCESS, payload: data })
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
        if (action.callback) {
          action.callback(false, error)
        }
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getSentRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/partner-requests?${objectToParams(action.payload)}&sendFromBusiness=${window.portal}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({
          type: types.GET_SENT_REQUEST_SUCCESS,
          payload: { data, meta }
        })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_SENT_REQUEST_FAIL })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_SENT_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getReceivedRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const portalPartner = window.portal === portals[0] ? portals[1] : portals[0]
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/partner-requests?${objectToParams(action.payload)}&sendFromBusiness=${portalPartner}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({
          type: types.GET_RECEIVED_REQUEST_SUCCESS,
          payload: { data, meta }
        })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_RECEIVED_REQUEST_FAIL })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_RECEIVED_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getPubicPartnerAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/partner/public-partner?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_PUBLIC_PARTNER_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* sendRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/partner-requests?${objectToParams({ businessType: action.businessType })}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    const publicPartners = yield select(getPublicPartners)

    for (let i = 0; i < publicPartners.data.length; i++) {
      if (publicPartners.data[i].id === action.partnerId) {
        publicPartners.data[i].requestId = data.id
      }
    }
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback()
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS }),
          put({ type: types.GET_PUBLIC_PARTNER_SUCCESS, payload: publicPartners })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* cancelRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/partner-requests/${action.payload.requestId}`)
    yield put({ type: commonTypes.HIDE_LOADING })
    const publicPartners = yield select(getPublicPartners)
    for (let i = 0; i < publicPartners.data.length; i++) {
      if (publicPartners.data[i].requestId === action.payload.requestId) {
        delete publicPartners.data[i].requestId
      }
    }
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback()
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS }),
          put({ type: types.GET_PUBLIC_PARTNER_SUCCESS, payload: publicPartners })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/partner-requests/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback()
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeEvery(types.GET_PARTNERS_ASYNC, getPartnersAsync),
    takeLatest(types.GET_PARTNER_DETAIL_ASYNC, getPartnerDetailAsync),
    takeLatest(types.GET_PUBLIC_PARTNER_ASYNC, getPubicPartnerAsync),
    takeLatest(types.GET_RECEIVED_REQUEST_ASYNC, getReceivedRequestAsync),
    takeLatest(types.GET_SENT_REQUEST_ASYNC, getSentRequestAsync),
    takeLatest(types.UPDATE_REQUEST_ASYNC, updateRequestAsync),
    takeLatest(types.SEND_REQUEST_ASYNC, sendRequestAsync),
    takeLatest(types.CANCEL_REQUEST_ASYNC, cancelRequestAsync)
  ])
}
