import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as commonTypes from '../../containers/common/types'

class Notification extends Component {
  static propTypes = {
    commonReducer: PropTypes.object,
    dispatch: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.hiddenPopup = this.hiddenPopup.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }

  hiddenPopup() {
    const { commonReducer, dispatch } = this.props
    if (commonReducer.isShowSuccess) {
      dispatch({ type: commonTypes.HIDE_POPUP_SUCCESS })
    }
    // if (commonReducer.isShowError) {
    //   dispatch({ type: commonTypes.HIDE_POPUP_ERROR })
    // }
    // if (commonReducer.isShowWarning) {
    //   dispatch({ type: commonTypes.HIDE_POPUP_WARNING })
    // }
  }

  onClosePopup() {
    const { commonReducer, dispatch } = this.props
    if (commonReducer.isShowError) {
      dispatch({ type: commonTypes.HIDE_POPUP_ERROR })
    }
    if (commonReducer.isShowWarning) {
      dispatch({ type: commonTypes.HIDE_POPUP_WARNING })
    }
  }

  render() {
    const { commonReducer } = this.props
    if (!commonReducer.isShowSuccess && !commonReducer.isShowError && !commonReducer.isShowWarning) {
      return null
    }

    setTimeout(() => {
      this.hiddenPopup()
    }, 3000)

    if (commonReducer.isShowError) {
      return (
        <div className="swal2-container swal2-center swal2-fade swal2-shown" style={{ overflowY: 'auto' }}>
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="swal2-title"
            aria-describedby="swal2-content"
            className="swal2-popup swal2-modal swal2-show"
            tabIndex="-1"
            aria-live="assertive"
            style={{
              width: 400, padding: '2.5rem', background: 'rgb(255, 255, 255)', display: 'flex'
            }}
          >
            <div className="swal2-icon-close"><a href="javascript:" onClick={this.onClosePopup}><i className="fas fa-times" /></a></div>
            <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{ display: 'block' }}>
              <span className="swal2-x-mark swal2-animate-x-mark">
                <span className="swal2-x-mark-line-left" />
                <span className="swal2-x-mark-line-right" />
              </span>
            </div>
            <div className="swal2-contentwrapper">
              <h2 className="swal2-title" id="swal2-title">
                <div
                  dangerouslySetInnerHTML={{
                    __html: typeof (commonReducer.errorMsg) === 'object' ? commonReducer.errorMsg.message : commonReducer.errorMsg
                  }}
                />
              </h2>
              {/* <div id="swal2-content" className="swal2-content" style={{ display: 'block' }}>This message will be automatic close!</div> */}
            </div>
          </div>

        </div>
      )
    }

    if (commonReducer.isShowSuccess) {
      return true
      // return (
      //   <div className="swal2-container swal2-center swal2-fade swal2-shown" style={{ overflowY: 'auto' }}>
      //     <div
      //       role="dialog"
      //       aria-modal="true"
      //       aria-labelledby="swal2-title"
      //       aria-describedby="swal2-content"
      //       className="swal2-popup swal2-modal swal2-show"
      //       tabIndex="-1"
      //       aria-live="assertive"
      //       style={{
      //         width: 400, padding: '2.5rem', background: 'rgb(255, 255, 255)', display: 'flex'
      //       }}
      //     >
      //       <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{ display: 'block' }}>
      //         <div className="swal2-success-circular-line-left" style={{ background: 'rgb(255, 255, 255)' }} />
      //         <span className="swal2-success-line-tip swal2-animate-success-line-tip" />
      //         <span className="swal2-success-line-long swal2-animate-success-line-long" />
      //         <div className="swal2-success-ring" />
      //         <div className="swal2-success-fix" style={{ background: 'rgb(255, 255, 255)' }} />
      //         <div className="swal2-success-circular-line-right" style={{ background: 'rgb(255, 255, 255)' }} />
      //       </div>
      //       <div className="swal2-contentwrapper">
      //         <h2 className="swal2-title" id="swal2-title">Success!</h2>
      //         <div id="swal2-content" className="swal2-content" style={{ display: 'block' }}>This message will be automatic close!</div>
      //       </div>
      //     </div>

      //   </div>
      // )
    }

    if (commonReducer.isShowWarning) {
      return (
        <div className="swal2-container swal2-center swal2-fade swal2-shown" style={{ overflowY: 'auto' }}>
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="swal2-title"
            aria-describedby="swal2-content"
            className="swal2-popup swal2-modal swal2-show"
            tabIndex="-1"
            aria-live="assertive"
            style={{
              width: 400, padding: '2.5rem', background: 'rgb(255, 255, 255)', display: 'flex'
            }}
          >
            <div className="swal2-icon-close"><a href="javascript:" onClick={this.onClosePopup}><i className="fas fa-times" /></a></div>
            <div className="swal2-icon swal2-warning" style={{ display: 'block' }}>!</div>
            <div className="swal2-contentwrapper">
              {/* <h2 className="swal2-title" id="swal2-title">Warning!</h2> */}
              <div id="swal2-content" className="swal2-content" style={{ display: 'block', fontSize: 18 }}>
                {typeof (commonReducer.warningMsg) === 'object' ? commonReducer.warningMsg.message : commonReducer.warningMsg}
              </div>
            </div>
          </div>

        </div>
      )
    }
    return null
  }
}

function mapStateToProps(state) {
  return {
    commonReducer: state.commonReducer
  }
}

export default connect(mapStateToProps)(Notification)
