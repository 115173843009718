import * as types from './types'

const initialState = {
  manageCaseList: {
    data: [],
    meta: {}
  },
  caseDetail: {},
  ordersList: [],
  report: {
    open: 0,
    declined: 0,
    dropped: 0,
    'awaiting-payment': 0,
    paid: 0
  },
  discussion: { data: [], meta: {}, lastUpdated: new Date().getTime() }
}

export default function caseReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_CASE_SUCCESS:
      return {
        ...state,
        manageCaseList: action.payload
      }
    // case types.GET_LIST_CASE_ASYNC:
    case types.GET_LIST_CASE_FAIL:
      return {
        ...state,
        manageCaseList: {
          data: [],
          meta: {}
        }
      }
    case types.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerList: action.payload
      }

    case types.GET_CASE_DETAIL_SUCCESS:
      return {
        ...state,
        caseDetail: action.payload
      }

    case types.GET_CASE_DETAIL_ASYNC:
      return {
        ...state,
        caseDetail: {}
      }
    case types.GET_CASE_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload
        }
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    case types.GET_DISCUSSION_SUCCESS:
      return {
        ...state,
        discussion: action.payload
      }
    case types.GET_DISCUSSION_ASYNC:
    case types.GET_DISCUSSION_FAIL:
      return {
        ...state,
        discussion: []
      }

    case types.CREATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        discussion: [
          ...state.discussion,
          { ...action.payload }
        ]
      }
    case types.APPEND_DISCUSSION_SUCCESS:
      return {
        ...state,
        discussion: {
          ...action.payload,
          lastUpdated: new Date().getTime()
        }
      }

    default:
      return state
  }
}
