import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { persistStore, createTransform } from 'redux-persist'
import CryptoJS from 'crypto-js'
import I18n from 'redux-i18n'
import { createBrowserHistory } from 'history'
import 'core-js/fn/object/assign'
import 'core-js/fn/promise'

import store from './configs/store.config'
import * as serviceWorker from './serviceWorker'
import translations from './locales/translations'
import AppRoutes from './configs/router.config'
import {
  LOPRX_PHARMACY_URL,
  LOPRX_ATTORNEY_URL,
  LOPRX_CAPTCHA_SITE_KEY,
  socketIO
} from './api'

import './App.scss'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentWillMount() {
    const encryptTransform = createTransform(
      (inboundState) => {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(inboundState), 'UI2D4yfiIb4teTPE')
        return encrypted.toString()
      },
      (outboundState) => {
        const decrypted = CryptoJS.AES.decrypt(outboundState, 'UI2D4yfiIb4teTPE')
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
      },
      { whitelist: ['accountsReducer', 'i18nState'] }
    )

    persistStore(store, {
      transforms: [encryptTransform],
      whitelist: ['accountsReducer', 'i18nState']
    }, () => {
      socketIO.connect()
      this.setState({ loading: false })
    })

    window.portal = ''
    if (LOPRX_PHARMACY_URL.split(',').includes(window.location.origin)) { window.portal = 'pharmacy' }
    if (LOPRX_ATTORNEY_URL.split(',').includes(window.location.origin)) { window.portal = 'attorney' }
  }

  componentDidMount() {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)
      if (!isScriptExist) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = () => {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }
      if (isScriptExist && callback) callback()
    }
    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${LOPRX_CAPTCHA_SITE_KEY}`)

    window.addEventListener('storage', (e) => {
      if (e.key === 'user-status' && e.oldValue === '' && e.newValue === 'connected') {
        window.location.href = window.location.href
      }
    })
  }

  componentWillUnmount() {
    socketIO.disconnect()
  }

  render() {
    const { loading } = this.state

    const history = createBrowserHistory({ basename: '/' })
    // eslint-disable-next-line
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1]
    if (path) {
      history.replace(path)
    }

    if (loading === true) {
      return <div style={{ textAlign: 'center' }}>Initial application...</div>
    }
    return (
      <Provider store={store}>
        <I18n translations={translations}>
          <Router history={history}>
            <AppRoutes />
          </Router>
        </I18n>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
