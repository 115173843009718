import { filter, findIndex } from 'lodash'
import * as types from './types'

const initialState = {
  requestTrackers: []
}

export default function requestTrackerReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_REQUEST_TRACKER_SUCCESS:
      return {
        ...state,
        requestTrackers: action.payload.data
      }
    case types.GET_REQUEST_TRACKER_FAIL:
      return {
        ...state,
        requestTrackers: []
      }
    case types.APPEND_REQUEST_TRACKER_SUCCESS:
      return {
        ...state,
        requestTrackers: findIndex(state.requestTrackers, ['socketId', action.payload.socketId]) < 0 ? [
          ...state.requestTrackers,
          action.payload
        ] : [...state.requestTrackers].map((tracker, index) => {
          if (index === findIndex(state.requestTrackers, ['socketId', action.payload.socketId])) {
            tracker.timestamp = action.payload.timestamp
          }
          return tracker
        })
      }
    case types.REMOVE_REQUEST_TRACKER_SUCCESS:
      return {
        ...state,
        requestTrackers: filter([...state.requestTrackers], (obj) => {
          return obj.socketId !== action.payload.socketId
        })
      }
    default:
      return state
  }
}
