import * as types from './types'

const initialState = {
  requestSettings: {
    lop: {
      orderItemFields: []
    },
    lob: {
      orderItemFields: []
    }
  }
}

export default function submitReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_REQUEST_SETTINGS_SUCCESS:
      return {
        requestSettings: action.payload
      }
    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
