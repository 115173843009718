import axios from 'axios'

import { portals } from './constants'
import store from '../configs/store.config'

const Request = {
  callAPI(method, url, args) {
    return new Promise(async(resolve, reject) => {
      try {
        let accessToken = ''
        const { accountsReducer: { userInfo }, i18nState: { lang } } = store.getState()  // eslint-disable-line
        if (args && args.accessToken) {
          accessToken = args.accessToken // eslint-disable-line
          delete args.accessToken
        } else {
          accessToken = userInfo && userInfo.accessToken ? userInfo.accessToken : ''
        }
        const response = await axios({
          method,
          url,
          data: args,
          headers: {
            Authorization: accessToken
            // 'Access-Control-Allow-Origin': '*'
            // Language: lang
          }
        })
        if (process.env.NODE_ENV === 'development') {
          console.log('===SUCCESS===', url, response)
        }
        resolve({ ...response.data, status: response.data.code })
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('===ERROR development===', error.response)
        }

        if (error && error.response) {
          switch (error.response.status) {
            case 404:
              window.location.href = '/404'
              break

            default:
              reject(error.response)
              break
          }
        } else {
          reject(error.response)
        }
      }
    })
  },
  uploadFile(method, url, formData) {
    return new Promise(async(resolve, reject) => {
      try {
        let accessToken = ''
        const { accountsReducer: { userInfo }, i18nState: { lang } } = store.getState()  // eslint-disable-line
        accessToken = userInfo && userInfo.accessToken ? userInfo.accessToken : ''
        const response = await axios({
          method,
          url,
          data: formData,
          headers: {
            Authorization: accessToken,
            'Content-Type': 'multipart/form-data'
            // 'Access-Control-Allow-Origin': '*'
            // Language: lang
          }
        })
        if (process.env.NODE_ENV === 'development') {
          console.log('===SUCCESS UPLOAD===', url, response)
        }

        resolve({ ...response.data, status: response.data.code })
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('===ERROR UPLOAD development===', error.response)
        }

        if (error && error.response) {
          switch (error.response.status) {
            case 404:
              window.location.href = '/404'
              break

            default:
              reject(error.response)
              break
          }
        } else {
          reject(error.response)
        }
      }
    })
  },
  getFile(method, url) {
    return new Promise(async(resolve, reject) => {
      try {
        let accessToken = ''
        const { accountsReducer: { userInfo }, i18nState: { lang } } = store.getState()  // eslint-disable-line
        accessToken = userInfo && userInfo.accessToken ? userInfo.accessToken : ''
        const response = await axios({
          method,
          url,
          headers: {
            Authorization: accessToken,
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        })
        if (process.env.NODE_ENV === 'development') {
          console.log('=== SUCCESS GET FILE ===', url, response)
        }
        resolve({
          status: response.status,
          fileType: response.headers['content-type'],
          fileContent: response.data
        })
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('=== ERROR GET FILE ===', error.response)
        }

        if (error && error.response) {
          switch (error.response.status) {
            case 404:
              window.location.href = '/404'
              break

            default:
              reject(error.response)
              break
          }
        } else {
          reject(error.response)
        }
      }
    })
  }
}

export default Request
