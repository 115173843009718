import moment from 'moment'
import Moment from 'moment-timezone'
import { map, filter, findIndex } from 'lodash'

import {
  LOPRX_BASE_URL, version
} from '../api'

function getLabel(list, value, key = 'value') {
  const filteredItem = filter(list, (e) => {
    return e[key] === value
  })
  if (filteredItem && filteredItem.length) {
    return filteredItem[0].label
  }

  return value
}

function formatDate(date, format = 'MM/DD/YYYY', utcFormat = false, ignoreConvertToTimezone = false) {
  if (date) {
    if (utcFormat) return moment(date).utc().format(format)
    if (ignoreConvertToTimezone) {
      return moment.utc(date).format(format)
    }
    return parseTimezone(date, format) // moment(date).format(format)
  }
  return null
}

const customFilterOption = (labelName = 'label') => (option, rawInput) => {
  const words = rawInput.split(' ')
  return words.reduce(
    (acc, cur) => acc && option.data[labelName].toLowerCase().includes(cur.toLowerCase()),
    true,
  )
}

function parseArrayToArrayObject(array, isSetKey) {
  const results = []
  if (isSetKey) {
    for (let key in array) {
      if (Object.prototype.hasOwnProperty.call(array, key)) {
        results.push({ value: array[key], label: key })
      }
    }
  } else {
    for (let i = 0; i < array.length; i++) {
      results.push({ value: array[i], label: array[i] })
    }
  }

  return results
}

const calculatorDatetime = (inputDate) => {
  const now = new Date()
  const creatAt = new Date(moment.utc(inputDate).local().format())

  const offset = (now.getTime() / 1000) - (creatAt.getTime() / 1000) // take the deviation of two time points, the unit is millisecond
  const totalDays = Math.round(offset / 60 / 60 / 24)

  const totalHours = Math.round(offset / 60 / 60)

  const totalMinutes = Math.round(offset / 60)

  const totalSeconds = Math.round(offset)

  if (totalDays > 0) {
    return `${totalDays}d ago`
  }
  if (totalHours > 0) {
    return `${totalHours}h ago`
  }

  if (totalMinutes > 0) {
    return `${totalMinutes}m ago`
  }

  if (totalSeconds > 0) {
    return `${totalSeconds}s ago`
  }

  return 'just now'
}

const checkModules = (modules = [], name = 'orders', key = 'isEnabled') => {
  if (modules[name]) {
    return modules[name][key]
  }

  return false
}

const checkURL = (hasObjectInUrl) => {
  const url_string = window.location.href
  const url = new URL(url_string)
  let getObjectOnUrl = url.searchParams.keys(hasObjectInUrl) || ''
  if (getObjectOnUrl) {
    return true
  }
  return false
}

const checkPermissions = (listPermissions = [], moduleCode, operationsCode) => {
  if (listPermissions.length > 0) {
    // let objectOperationFilter = ''
    // let checkIsEnable = false
    // check list Permission
    // let objectPermission = filter(listPermissions, (permission) => {
    //   return permission.code === module
    // })

    // // Check and filter operations
    // if (objectPermission) {
    //   map(objectPermission, (value) => {
    //     objectOperationFilter = filter(value.operations, (operations) => {
    //       return operations.code === operationsCode
    //     })
    //   })
    // }

    // // check isEnabled
    // if (objectOperationFilter) {
    //   map(objectOperationFilter, (action) => {
    //     checkIsEnable = action.isEnabled
    //   })
    // }
    // return checkIsEnable
    let MODULE_INDEX = _.findIndex(listPermissions, { code: moduleCode })
    let PERMISSION_INDEX = (MODULE_INDEX >= 0) ? _.findIndex(listPermissions[MODULE_INDEX].operations, { code: operationsCode }) : -1
    return (PERMISSION_INDEX >= 0) ? listPermissions[MODULE_INDEX].operations[PERMISSION_INDEX].isEnabled : false
  }
  return false
}

const objectToParams = (data) => {
  if (data) {
    return Object.keys(data).map((i) => {
      if (typeof data[i] === 'object') {
        return `${i}=${JSON.stringify(data[i])}`
      }
      return `${i}=${encodeURIComponent(data[i])}`
    }).join('&')
  }
  return ''
}

function formatFileSize(size, decimals = 1) {
  var kilobyte = 1024
  var megabyte = kilobyte * 1024
  var gigabyte = megabyte * 1024
  var terabyte = gigabyte * 1024
  if ((size >= 0) && (size < kilobyte)) {
    return `${size} B`
  }
  if ((size >= kilobyte) && (size < megabyte)) {
    return `${(size / kilobyte).toFixed(decimals)} KB`
  }
  if ((size >= megabyte) && (size < gigabyte)) {
    return `${(size / megabyte).toFixed(decimals)} MB`
  }
  if ((size >= gigabyte) && (size < terabyte)) {
    return `${(size / gigabyte).toFixed(decimals)} GB`
  }
  return `${size} B`
}

function downloadURI(urlPrams = '', filename, accessToken) {
  let fetchUrl = ''
  const url = urlPrams ? urlPrams.toString() : ''
  if (url.indexOf('http') > -1) {
    fetchUrl = url
  } else {
    fetchUrl = `${LOPRX_BASE_URL}/${version}/business/files/${url}`
  }
  fetch(fetchUrl, {
    headers: { Authorization: accessToken }
  }).then((t) => {
    return t.blob().then((b) => {
      var a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', filename)
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
  })
}

function printAttachmentFromURI(urlPrams = '', filename, accessToken, callback) {
  let fetchUrl = ''
  const url = urlPrams ? urlPrams.toString() : ''
  if (url.indexOf('http') > -1) {
    fetchUrl = url
  } else {
    fetchUrl = `${LOPRX_BASE_URL}/${version}/business/files/${url}`
  }
  fetch(fetchUrl, {
    headers: { Authorization: accessToken }
  }).then((t) => {
    let supportFile = {
      'application/pdf': 'pdf',
      'image/jpeg': 'image',
      'image/png': 'image',
      'text/html': 'html',
      'text/plain': 'raw-html'
    }
    // pdf, html, image, json and raw-html
    return t.blob().then((data) => {
      if (typeof supportFile[data.type] === 'undefined') {
        callback(false, 'File is not supported')
      } else {
        printPdfBlob(data)
      }
    })
  })
}

function printPdfBlob(pdfBlob) {
  var isFirefox = /Gecko\/\d/.test(navigator.userAgent)
  var firefoxDelay = 1000
  var iframe
  if (iframe) {
    iframe.parentNode.removeChild(iframe)
  }

  iframe = document.createElement('iframe')
  iframe.style.cssText = 'width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0'

  var iframeUrl = URL.createObjectURL(pdfBlob)
  iframe.src = iframeUrl

  iframe.addEventListener('load', () => {
    function printIframe() {
      try {
        iframe.focus()
        try {
          iframe.contentWindow.document.execCommand('print', false, null)
        } catch (e) {
          iframe.contentWindow.print()
        }
      } catch (error) {
        console.error('Print failed: ', error)
      } finally {
        iframe.style.visibility = 'hidden'
        iframe.style.left = '-1px'
        URL.revokeObjectURL(iframeUrl)
      }
    }

    // Add a delay for Firefox
    if (isFirefox) {
      window.setTimeout(printIframe, firefoxDelay)
    } else {
      printIframe()
    }
  })
  document.body.appendChild(iframe)
}

function printLetterAndAttachment(requestId, letterId, accessToken) {
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`
  let dataRequest = {
    action: 'print_letter',
    data: {
      requestId,
      letterId
    }
  }

  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    return response.blob().then((data) => {
      printPdfBlob(data)
    })
  })
}

function exportFilePDF(event, requestId, letterId, filename, accessToken) {
  let element = event.currentTarget
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`
  let dataRequest = {
    action: 'export_letter',
    data: {
      requestId,
      letterId
    }
  }

  element.querySelector('img').src = '/assets/img/ico-export-loading.gif'

  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    if (response.status === 200) {
      response.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', `${filename}.pdf`)
        document.body.appendChild(a)
        a.click()
        a.remove()
        element.querySelector('img').src = '/assets/img/ico-export-success.png'
        setTimeout(() => {
          element.querySelector('img').src = '/assets/img/iconpdf.png'
        }, 2000)
      })
    } else {
      element.querySelector('img').src = '/assets/img/ico-export-error.png'
      setTimeout(() => {
        element.querySelector('img').src = '/assets/img/iconpdf.png'
      }, 2000)
    }
  }).catch(() => {
    element.querySelector('img').src = '/assets/img/ico-export-error.png'
    setTimeout(() => {
      element.querySelector('img').src = '/assets/img/iconpdf.png'
    }, 2000)
  })
}

function exportRequest(event, requestId, accessToken) {
  let element = event.currentTarget
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`
  let dataRequest = {
    action: 'export_request',
    data: {
      requestId
    }
  }

  element.querySelector('img').src = '/assets/img/ico-export-loading.gif'

  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    if (response.status === 200) {
      let fileName = `Request #${requestId}`
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
      response.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        if (isFirefox) {
          a.setAttribute('download', `${fileName}.zip`)
        } else {
          a.setAttribute('download', fileName)
        }
        document.body.appendChild(a)
        a.click()
        a.remove()
        element.querySelector('img').src = '/assets/img/ico-export-success.png'
        setTimeout(() => {
          element.querySelector('img').src = '/assets/img/iconpdf.png'
        }, 2000)
      })
    } else {
      element.querySelector('img').src = '/assets/img/ico-export-error.png'
      setTimeout(() => {
        element.querySelector('img').src = '/assets/img/iconpdf.png'
      }, 2000)
    }
  }).catch(() => {
    element.querySelector('img').src = '/assets/img/ico-export-error.png'
    setTimeout(() => {
      element.querySelector('img').src = '/assets/img/iconpdf.png'
    }, 2000)
  })
}

function exportListCase(event, filterParams, accessToken) {
  let element = event.currentTarget
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`

  if (filterParams.businessIds.length > 0) filterParams.businessIds = map(filterParams.businessIds, business => business.businessId).join(',')

  let dataRequest = {
    action: 'export_list_case',
    data: filterParams
  }
  element.querySelector('i').style.display = 'none'
  element.querySelector('img').style.height = '25px'
  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    if (response.status === 200) {
      let fileName = `List Case ${moment().format('MM/DD/YYYY HH:mm')}.csv`
      response.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        a.remove()
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
      })
    } else {
      return response.json()
    }
  })
    .then((data) => {
      if (data && data.code !== 200) {
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
        return Swal.fire({
          type: 'error',
          title: data.message
        })
      }
      return null
    })
}

function exportListClient(event, filterParams, accessToken) {
  let element = event.currentTarget
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`
  let dataRequest = {
    action: 'export_list_client',
    data: filterParams
  }
  element.querySelector('i').style.display = 'none'
  element.querySelector('img').style.height = '25px'
  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    if (response.status === 200) {
      let fileName = `List Client ${moment().format('MM/DD/YYYY HH:mm')}.csv`
      response.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        a.remove()
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
      })
    } else {
      return response.json()
    }
  })
    .then((data) => {
      if (data && data.code !== 200) {
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
        return Swal.fire({
          type: 'error',
          title: data.message
        })
      }
      return null
    })
}

function exportListPatient(event, filterParams, accessToken) {
  let element = event.currentTarget
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`
  let dataRequest = {
    action: 'export_list_patient',
    data: filterParams
  }
  element.querySelector('i').style.display = 'none'
  element.querySelector('img').style.height = '25px'
  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    if (response.status === 200) {
      let fileName = `List Patient ${moment().format('MM/DD/YYYY HH:mm')}.csv`
      response.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        a.remove()
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
      })
    } else {
      return response.json()
    }
  })
    .then((data) => {
      if (data && data.code !== 200) {
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
        return Swal.fire({
          type: 'error',
          title: data.message
        })
      }
      return null
    })
}

function exportListRequest(event, filterParams, accessToken) {
  let element = event.currentTarget
  let fetchUrl = `${LOPRX_BASE_URL}/${version}/business/helpers`

  filterParams.businessIds = filterParams.businessIds ? _.map(filterParams.businessIds, 'value').join(',') : ''
  filterParams.patientIds = filterParams.patientIds ? _.map(filterParams.patientIds, 'value').join(',') : ''
  filterParams.clientIds = filterParams.clientIds ? _.map(filterParams.clientIds, 'value').join(',') : ''

  let dataRequest = {
    action: 'export_list_request',
    data: filterParams
  }
  element.querySelector('i').style.display = 'none'
  element.querySelector('img').style.height = '25px'
  fetch(fetchUrl, {
    method: 'post',
    headers: { Authorization: accessToken },
    body: JSON.stringify(dataRequest)
  }).then((response) => {
    if (response.status === 200) {
      let fileName = `List Request ${moment().format('MM/DD/YYYY HH:mm')}.csv`
      response.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        a.remove()
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
      })
    } else {
      return response.json()
    }
  })
    .then((data) => {
      if (data && data.code !== 200) {
        element.querySelector('i').style.display = 'initial'
        element.querySelector('img').style.height = '0px'
        return Swal.fire({
          type: 'error',
          title: data.message
        })
      }
      return null
    })
}

function isEmailValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function removeDashText(text) {
  return text.replace(/-/g, ' ')
}

function parseTimezone(value = '', format = 'DD/MM/YYYY') {
  // return moment(value).format(format)
  return Moment.tz(value, Moment.tz.guess()).format(format)
}

function handleResize() {
  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

  let resize = window.addEventListener('resize', handleResize)
  return resize
}

function checkNestedObject(obj = {}, ...args) {
  for (let i = 0; i < args.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}

export {
  getLabel,
  formatDate,
  formatFileSize,
  customFilterOption,
  parseArrayToArrayObject,
  calculatorDatetime,
  checkModules,
  objectToParams,
  downloadURI,
  exportFilePDF,
  exportRequest,
  exportListCase,
  exportListRequest,
  exportListClient,
  exportListPatient,
  isEmailValid,
  printAttachmentFromURI,
  parseTimezone,
  removeDashText,
  checkPermissions,
  checkURL,
  printLetterAndAttachment,
  handleResize,
  checkNestedObject,
  printPdfBlob
}
