import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'
import { objectToParams } from '../../helpers/utils'

function* getClientsAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/customers?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_CLIENTS_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_CLIENTS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_CLIENTS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getClientDetailAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/customers/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_CLIENT_DETAIL_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_CLIENT_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_CLIENT_DETAIL_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createClientAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/customers`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_CLIENT_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_CLIENT_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* editClientAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/customers/${action.id}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* uploadFile(action) {
  try {
    const { data, meta } = yield call(request.uploadFile, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers?action=import_list_client&data[override]=${action.override}&data[defineBy]=${action.defineBy}&data[updateFrom]=${action.updateFrom}&data[updateTo]=${action.updateTo}`, action.payload.params)
    if (action.callback) {
      action.callback(true, data, meta)
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.GET_CLIENTS_ASYNC, getClientsAsync),
    takeLatest(types.CREATE_CLIENT_ASYNC, createClientAsync),
    takeLatest(types.EDIT_CLIENT_ASYNC, editClientAsync),
    takeEvery(types.GET_CLIENT_DETAIL_ASYNC, getClientDetailAsync),
    takeLatest(types.IMPORT_CLIENT_ASYNC, uploadFile)
  ])
}
