import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import { objectToParams } from '../../helpers/utils'

function* getResolutionReportAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/report?${objectToParams(action.payload)}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_RESOLUTION_REPORT_SUCCESS, payload: { data } })
        break
      }
      default: {
        yield [
          put({ type: types.GET_RESOLUTION_REPORT_FAIL })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_RESOLUTION_REPORT_FAIL })
    ]
  }
}

function* getRequestReportAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/report?${objectToParams(action.payload)}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_REPORT_SUCCESS, payload: { data } })
        break
      }
      default: {
        yield [
          put({ type: types.GET_REQUEST_REPORT_FAIL })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_REPORT_FAIL })
    ]
  }
}

export default function* watchAction() {
  yield all([
    takeEvery(types.GET_RESOLUTION_REPORT_ASYNC, getResolutionReportAsync),
    takeEvery(types.GET_REQUEST_REPORT_ASYNC, getRequestReportAsync)
  ])
}
