export const GET_PATIENTS_ASYNC = 'GET_PATIENTS_ASYNC'
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS'
export const GET_PATIENTS_FAIL = 'GET_PATIENTS_FAIL'

export const GET_PATIENT_DETAIL_ASYNC = 'GET_PATIENT_DETAIL_ASYNC'
export const GET_PATIENT_DETAIL_SUCCESS = 'GET_PATIENT_DETAIL_SUCCESS'
export const GET_PATIENT_DETAIL_FAIL = 'GET_PATIENT_DETAIL_FAIL'

export const CREATE_PATIENT_ASYNC = 'CREATE_PATIENT_ASYNC'
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS'
export const CREATE_PATIENT_FAIL = 'CREATE_PATIENT_FAIL'

export const EDIT_PATIENT_ASYNC = 'EDIT_PATIENT_ASYNC'
export const EDIT_PATIENT_SUCCESS = 'EDIT_PATIENT_SUCCESS'
export const EDIT_PATIENT_FAIL = 'EDIT_PATIENT_FAIL'

export const IMPORT_PATIENT_ASYNC = 'IMPORT_PATIENT_ASYNC'

