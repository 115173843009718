export const SIGN_UP_ASYNC = 'SIGN_UP_ASYNC'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'

export const SIGN_IN_ASYNC = 'SIGN_IN_ASYNC'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL'

export const SIGN_IN_VERIFY_TOKEN_ASYNC = 'SIGN_IN_VERIFY_TOKEN_ASYNC'
export const SIGN_IN_VERIFY_TOKEN_SUCCESS = 'SIGN_IN_VERIFY_TOKEN_SUCCESS'
export const SIGN_IN_VERIFY_TOKEN_FAIL = 'SIGN_IN_VERIFY_TOKEN_FAIL'

export const FORGET_PASSWORD_ASYNC = 'FORGET_PASSWORD_ASYNC'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL'

export const GET_USER_PROFILE_ASYNC = 'GET_USER_PROFILE_ASYNC'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL'

export const UPDATE_USER_PROFILE_ASYNC = 'UPDATE_USER_PROFILE_ASYNC'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL'

export const LOG_OUT = 'LOG_OUT'

export const GET_PROFILE_METADATA_ASYNC = 'GET_PROFILE_METADATA_ASYNC'
export const GET_PROFILE_METADATA_SUCCESS = 'GET_PROFILE_METADATA_SUCCESS'
export const GET_PROFILE_METADATA_FAIL = 'GET_PROFILE_METADATA_FAIL'

export const UPDATE_PROFILE_METADATA_ASYNC = 'UPDATE_PROFILE_METADATA_ASYNC'
export const UPDATE_PROFILE_METADATA_SUCCESS = 'UPDATE_PROFILE_METADATA_SUCCESS'
export const UPDATE_PROFILE_METADATA_FAIL = 'UPDATE_PROFILE_METADATA_FAIL'

export const GET_URL_BEFORE_SIGN_ASYNC = 'GET_URL_BEFORE_SIGN_ASYNC'
export const GET_URL_BEFORE_SIGN_SUCCESS = 'GET_URL_BEFORE_SIGN_SUCCESS'
export const GET_URL_BEFORE_SIGN_FAIL = 'GET_URL_BEFORE_SIGN_FAIL'
