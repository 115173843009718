import {
  call, put, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'
import { objectToParams } from '../../helpers/utils'

function* getListCaseDashboardAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_LIST_CASE_DASHBOARD_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_LIST_CASE_DASHBOARD_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_LIST_CASE_DASHBOARD_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getListRequestDashboardAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_TRACK_REQUEST_DASHBOARD_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_TRACK_REQUEST_DASHBOARD_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_TRACK_REQUEST_DASHBOARD_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeEvery(types.GET_LIST_CASE_DASHBOARD_ASYNC, getListCaseDashboardAsync),
    takeEvery(types.GET_TRACK_REQUEST_DASHBOARD_ASYNC, getListRequestDashboardAsync)
  ])
}

