export const CREATE_CASE_ASYNC = 'CREATE_CASE_ASYNC'
export const CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS'
export const CREATE_CASE_FAIL = 'CREATE_CASE_FAIL'

export const GET_CASE_ASYNC = 'GET_CASE_ASYNC'
export const GET_CASE_SUCCESS = 'GET_CASE_SUCCESS'
export const GET_CASE_FAIL = 'GET_CASE_FAIL'

export const CREATE_REQUEST_ASYNC = 'CREATE_REQUEST_ASYNC'
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS'
export const CREATE_REQUEST_FAIL = 'CREATE_REQUEST_FAIL'

export const CREATE_DISCUSSION_ASYNC = 'CREATE_DISCUSSION_ASYNC'
export const CREATE_DISCUSSION_SUCCESS = 'CREATE_DISCUSSION_SUCCESS'
export const CREATE_DISCUSSION_FAIL = 'CREATE_DISCUSSION_FAIL'

export const UPLOAD_ATTACHMENT_ASYNC = 'UPLOAD_ATTACHMENT_ASYNC'
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS'
export const UPLOAD_ATTACHMENT_FAIL = 'UPLOAD_ATTACHMENT_FAIL'

export const GET_REQUEST_SETTINGS_ASYNC = 'GET_REQUEST_SETTINGS_ASYNC'
export const GET_REQUEST_SETTINGS_SUCCESS = 'GET_REQUEST_SETTINGS_SUCCESS'
export const GET_REQUEST_SETTINGS_FAIL = 'GET_REQUEST_SETTINGS_FAIL'

