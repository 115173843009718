import Loadable from 'react-loadable'
import Loading from '../components/loading'

const IntroPage = Loadable({
  loader: () => import('../containers/intro'),
  loading: Loading
})

const AboutUsPage = Loadable({
  loader: () => import('../containers/about/aboutContainer'),
  loading: Loading
})

const PrivacyPage = Loadable({
  loader: () => import('../containers/privacy/privacyContainer'),
  loading: Loading
})

const LoginPage = Loadable({
  loader: () => import('../containers/accounts/containers/loginContainer'),
  loading: Loading
})

const SignUpPage = Loadable({
  loader: () => import('../containers/accounts/containers/signUpContainer'),
  loading: Loading
})

const ForgetPasswordPage = Loadable({
  loader: () => import('../containers/accounts/containers/forgetPasswordContainer'),
  loading: Loading
})

const MyProfilePage = Loadable({
  loader: () => import('../containers/accounts/containers/myProfileContainer'),
  loading: Loading
})

const ReportPage = Loadable({
  loader: () => import('../containers/report'),
  loading: Loading
})

const DashboardPage = Loadable({
  loader: () => import('../containers/dashboard/containers/dashboardContainer'),
  loading: Loading
})

const TrackRequestManagePage = Loadable({
  loader: () => import('../containers/trackRequest/containers/TrackRequestContainer'),
  loading: Loading
})

const SubmitAffidavitRequestPage = Loadable({
  loader: () => import('../containers/submitRequest/containers/submitAffidavitRequestContainer.attorney'),
  loading: Loading
})

const TrackRequestDetailPage = Loadable({
  loader: () => import('../containers/trackRequest/containers/TrackRequestDetailContainer'),
  loading: Loading
})

const ClientManagePage = Loadable({
  loader: () => import('../containers/clientManage/containers/clientManageContainer'),
  loading: Loading
})
const PartnerDetailPage = Loadable({
  loader: () => import('../containers/partnerManage/containers/partnerDetailContainer'),
  loading: Loading
})

const CreateClientPage = Loadable({
  loader: () => import('../containers/clientManage/containers/createClientContainer'),
  loading: Loading
})

const ImportClientPage = Loadable({
  loader: () => import('../containers/clientManage/containers/importClientContainer'),
  loading: Loading
})

const ClientDetailPage = Loadable({
  loader: () => import('../containers/clientManage/containers/clientDetailContainer'),
  loading: Loading
})

const ManageNetworkPage = Loadable({
  loader: () => import('../containers/manageNetwork/containers/manageNetworkContainer'),
  loading: Loading
})

const SettingsPage = Loadable({
  loader: () => import('../containers/settings/containers/settingsContainer'),
  loading: Loading
})

const CreateEmployeePage = Loadable({
  loader: () => import('../containers/settings/containers/createEmployee'),
  loading: Loading
})

const EditEmployeePage = Loadable({
  loader: () => import('../containers/settings/containers/editEmployee'),
  loading: Loading
})

const CreateTemplatePage = Loadable({
  loader: () => import('../containers/settings/containers/createTemplate'),
  loading: Loading
})

const EditTemplatePage = Loadable({
  loader: () => import('../containers/settings/containers/editTemplate'),
  loading: Loading
})

const EditRolePage = Loadable({
  loader: () => import('../containers/settings/containers/editRole'),
  loading: Loading
})

const CreateRolePage = Loadable({
  loader: () => import('../containers/settings/containers/createRole'),
  loading: Loading
})

const EditGroupPage = Loadable({
  loader: () => import('../containers/settings/containers/editGroup'),
  loading: Loading
})

const CreateGroupPage = Loadable({
  loader: () => import('../containers/settings/containers/createGroup'),
  loading: Loading
})

const ManageCasePage = Loadable({
  loader: () => import('../containers/manageCase/containers/manageCaseContainer'),
  loading: Loading
})

const CaseDetailPage = Loadable({
  loader: () => import('../containers/manageCase/containers/caseDetailContainer'),
  loading: Loading
})

const CreateCase = Loadable({
  loader: () => import('../containers/manageCase/containers/createCase'),
  loading: Loading
})

const NotificationsPage = Loadable({
  loader: () => import('../containers/notifications/containers/notificationsContainer'),
  loading: Loading
})

const NotFoundPage = Loadable({
  loader: () => import('../containers/errorPage/notFound'),
  loading: Loading
})

const CreateLoPPage = Loadable({
  loader: () => import('../containers/submitRequest/containers/SubmitLoPContainer'),
  loading: Loading
})
const SubmitRequestPage = Loadable({
  loader: () => import('../containers/submitRequest/containers/page'),
  loading: Loading
})

const PatientManagePage = Loadable({
  loader: () => import('../containers/patientManage/containers/patientManageContainer'),
  loading: Loading
})

const CreatePatientPage = Loadable({
  loader: () => import('../containers/patientManage/containers/createPatientContainer'),
  loading: Loading
})

const ImportPatientPage = Loadable({
  loader: () => import('../containers/patientManage/containers/importPatientContainer'),
  loading: Loading
})

const PatientDetailPage = Loadable({
  loader: () => import('../containers/patientManage/containers/patientDetailContainer'),
  loading: Loading
})

const SubmitLOBInvoicePage = Loadable({
  loader: () => import('../containers/submitRequest/containers/submitLOBInvoice.container'),
  loading: Loading
})

const SubmitGeneralRequest = Loadable({
  loader: () => import('../containers/submitRequest/containers/general'),
  loading: Loading
})

const SubmitTechnicalRequest = Loadable({
  loader: () => import('../containers/submitRequest/containers/technical'),
  loading: Loading
})

const Automation = Loadable({
  loader: () => import('../containers/automation/containers/automationContainer'),
  loading: Loading
})

const RequestTracker = Loadable({
  loader: () => import('../containers/request-tracker'),
  loading: Loading
})

const RequestLogs = Loadable({
  loader: () => import('../containers/request-logs'),
  loading: Loading
})

export default [
  {
    path: '/privacy-policy-and-terms',
    component: AboutUsPage
  },
  {
    path: '/privacy',
    component: PrivacyPage
  },
  {
    path: '/',
    component: IntroPage,
    private: true
  },
  {
    path: '/sign-up',
    component: SignUpPage
  },
  {
    path: '/sign-in',
    component: LoginPage
  },
  {
    path: '/forget-password',
    component: ForgetPasswordPage
  },
  {
    path: '/profile',
    component: MyProfilePage,
    private: true
  },
  {
    path: '/report',
    component: ReportPage,
    private: true
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    private: true
  },
  {
    path: '/client-management',
    component: ClientManagePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/client-management/:id',
    component: ClientDetailPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/client-management/create',
    component: CreateClientPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/client-management/import',
    component: ImportClientPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/network-management',
    component: ManageNetworkPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/submit-request',
    component: SubmitRequestPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/submit-request/general',
    component: SubmitGeneralRequest,
    private: true,
    isFullInformation: true
  },
  {
    path: '/submit-request/technical',
    component: SubmitTechnicalRequest,
    private: true,
    isFullInformation: true
  },
  {
    path: '/submit-request/affidavit',
    component: SubmitAffidavitRequestPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/track-request',
    component: TrackRequestManagePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/track-request/:id',
    component: TrackRequestDetailPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings',
    component: SettingsPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/create-employee',
    component: CreateEmployeePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/employee/:id',
    component: EditEmployeePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/create-template',
    component: CreateTemplatePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/template/:id',
    component: EditTemplatePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/role/:id',
    component: EditRolePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/create-role',
    component: CreateRolePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/group/:id',
    component: EditGroupPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/settings/create-group',
    component: CreateGroupPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/case-management',
    component: ManageCasePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/case-management/:id',
    component: CaseDetailPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/case-management/create',
    component: CreateCase,
    private: true,
    isFullInformation: true
  },
  {
    path: '/notifications',
    component: NotificationsPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/partner-detail/:id',
    component: PartnerDetailPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/patient-management',
    component: PatientManagePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/patient-management/:id',
    component: PatientDetailPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/patient-management/create',
    component: CreatePatientPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/patient-management/import',
    component: ImportPatientPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/submit-request/lop',
    component: CreateLoPPage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/submit-request/lob',
    component: SubmitLOBInvoicePage,
    private: true,
    isFullInformation: true
  },
  {
    path: '/automation',
    component: Automation,
    private: true,
    isFullInformation: true
  },
  {
    path: '/request-tracker',
    component: RequestTracker,
    private: true,
    isFullInformation: true
  },
  {
    path: '/request-logs',
    component: RequestLogs,
    private: true,
    isFullInformation: true
  },
  {
    path: '/404',
    component: NotFoundPage
  }
]
