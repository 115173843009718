import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import { socketIO } from '../../api'
import { calculatorDatetime, formatDate } from '../../helpers/utils'
import * as notificationTypes from '../../containers/notifications/types'
import ImageThumbnail from '../imageThumbnail'
import * as AccountTypes from '../../containers/accounts/types'

class Header extends Component {
static propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func,
  userInfo: PropTypes.object,
  notificationList: PropTypes.object
}

constructor(prop) {
  super(prop)
  this.state = {
  }

  this.onClickLogOut = this.onClickLogOut.bind(this)
  this.subscribeNotification = this.subscribeNotification.bind(this)
  this.appendNotifications = this.appendNotifications.bind(this)
  this.updateNotify = this.updateNotify.bind(this)
  this.getNotifications = this.getNotifications.bind(this)
  this.getProfile = this.getProfile.bind(this)
  this.onClickSeeAllNotifications = this.onClickSeeAllNotifications.bind(this)
}

componentDidMount() {
  this.getNotifications()
}

onClickLogOut() {
  const { dispatch } = this.props
  dispatch({ type: 'LOG_OUT' })
  setTimeout(() => {
    socketIO.disconnect(null, () => { window.location.href = '/' })
  }, 500)
}

getNotifications() {
  socketIO.socketIOGet('/v1/business/notifications', this.subscribeNotification)
}

getProfile() {
  const { dispatch, history } = this.props
  dispatch({
    type: AccountTypes.GET_USER_PROFILE_ASYNC,
    callback: (status, data) => {
      let link = '/profile'
      if (status) {
        history.push(link)
      } else {
        return Swal.fire({
          type: 'error',
          title: `${data.data.message}`
        })
      }
    }
  })
}

subscribeNotification(data) {
  const { userInfo, dispatch } = this.props
  if (data) {
    dispatch({
      type: notificationTypes.GET_NOTIFICATIONS_SUCCESS,
      payload: { data: data.data, meta: data.meta }
    })
  }
  socketIO.subscribe(`notification-${userInfo.id}`, this.appendNotifications)
}

appendNotifications(response) {
  const { dispatch, notificationList: { data } } = this.props
  let mgs = { notificationList: { data } }
  let audio = new Audio('/assets/img/ding.wav')
  if (mgs) {
    audio.play()
  }
  dispatch({
    type: notificationTypes.APPEND_NOTIFICATIONS_SUCCESS,
    payload: {
      data: uniqBy([response.data].concat(data), 'id'),
      meta: response.meta
    }
  })
}

updateNotify(id) {
  const { dispatch } = this.props
  dispatch({
    type: notificationTypes.UPDATE_NOTIFICATIONS_ASYNC,
    payload: {
      id
    },
    callback: this.getNotifications
  })
}

onClickNotify = notify => (e) => {
  e.preventDefault()
  const { history, onToggleTopBar } = this.props
  if (!notify.isRead) {
    this.updateNotify(notify.id)
  }
  history.push({
    pathname: `${notify.targetUrl}`,
    state: { notify, time: new Date() }
  })
  window.location.reload()
  if (window.screen.width <= 768) {
    onToggleTopBar()
    $('.m-dropdown__dropoff').click()
  }
}

onClickSeeAllNotifications() {
  const { history } = this.props
  $('.m-topbar__notifications').click()
  history.push('/notifications')
}

render() {
  const {
    userInfo, notificationList: { data, meta }, onToggleLeftSideBar
  } = this.props

  return (
    <header className="m-grid__item m-header " data-minimize-offset="200" data-minimize-mobile-offset="200">
      <div className="m-container m-container--fluid m-container--full-height">
        <div className="m-stack m-stack--ver m-stack--desktop">
          {window.portal === 'pharmacy'
            ? (
              <div className="m-stack__item m-brand  m-brand--skin-dark ">
                <div className="m-stack m-stack--ver m-stack--general">
                  <div className="m-stack__item m-stack__item--middle m-stack__item--center m-brand__logo">
                    <Link to="/submit-request" className="m-brand__logo-wrapper d-none">
                      <img alt="" src="/assets/img/logo.png" />
                    </Link>
                  </div>
                  <div className="m-stack__item m-stack__item--middle m-brand__tools">
                    <a href="javascript:" onClick={onToggleLeftSideBar} className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                      <span />
                    </a>
                    {/* id="m_aside_left_offcanvas_toggle" */}
                    {/* <a id="m_aside_header_menu_mobile_toggle" href="javascript:" className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                  <span />
                </a> */}
                    {/* id="m_aside_header_topbar_mobile_toggle" */}
                  </div>
                </div>
              </div>
            )
            : (
              <div className="m-stack__item m-brand  m-brand--skin-dark " style={{ background: '#572900' }}>
                <div className="m-stack m-stack--ver m-stack--general">
                  <div className="m-stack__item m-stack__item--middle m-stack__item--center m-brand__logo">
                    <Link to="/submit-request" className="m-brand__logo-wrapper d-none">
                      <img alt="" src="/assets/img/logo.png" />
                    </Link>
                  </div>
                  <div className="m-stack__item m-stack__item--middle m-brand__tools">
                    <a href="javascript:" onClick={onToggleLeftSideBar} className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                      <span />
                    </a>
                    {/* id="m_aside_left_offcanvas_toggle" */}
                    {/* <a id="m_aside_header_menu_mobile_toggle" href="javascript:" className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                  <span />
                </a> */}
                    {/* id="m_aside_header_topbar_mobile_toggle" */}
                  </div>
                </div>
              </div>
            )
          }

          <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">

            <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general">
              <div className="m-stack__item m-topbar__nav-wrapper">
                <ul className="m-topbar__nav m-nav m-nav--inline">
                  <li className="d-none m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width technical-icon" data-dropdown-toggle="click" data-dropdown-persistent="true">
                    <a style={{ width: '100%' }} href="/submit-request/technical" className="m-nav__link" id="m_topbar_technical_icon">
                      <span
                        className="m-nav__link-icon"
                        style={{
                          textAlign: 'center',
                          lineHeight: '0',
                          verticalAlign: 'middle',
                          padding: 0
                        }}
                      >
                        <i className="fas fa-life-ring" />
                      </span>
                      <label
                        className="m--hidden-tablet-and-mobile"
                        style={{
                          position: 'absolute',
                          left: '-26%',
                          bottom: 0,
                          fontSize: '10px',
                          background: 'white'
                        }}
                      >Tech Support
                      </label>
                    </a>
                  </li>
                  <li className="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width">
                    <Dropdown className="dropdown-notification">
                      <Dropdown.Toggle className="clean btn-sm btn-icon btn-icon-lg">
                        <a href="javascript:" className="m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon">
                          {
                            meta && meta.totalUnread ? <span className="m-nav__link-badge m-badge m-badge--accent">{meta.totalUnread}</span> : ''
                          }
                          <span className="m-nav__link-icon">
                            <i className="far fa-bell" />
                          </span>
                          <label className="m--hidden-tablet-and-mobile">Notifications</label>
                        </a>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-0" style={{ width: '380px' }}>
                        <Dropdown.Item className="p-0">
                          <div className="m-dropdown__inner">
                            <div className="m-dropdown__header m--align-center" id="notification-icon">
                              <span className="m-dropdown__header-title">
                                {meta ? meta.totalUnread : 0}
                                {' '}
New
                              </span>
                              <span className="m-dropdown__header-subtitle">User Notifications</span>
                            </div>
                            <div className="m-dropdown__body" style={{ maxHeight: 400, overflow: 'auto' }}>
                              <div className="m-dropdown__content">
                                <div className="m-scrollable" data-scrollable="true" data-max-height="250" data-mobile-max-height="200">
                                  <div className="m-list-timeline m-list-timeline--skin-light m--margin-bottom-20">
                                    <div className="m-list-timeline__items">
                                      {
                                        !isEmpty(data) && (
                                          map(data, (item, index) => {
                                            return (
                                              <a
                                                href="javascript:"
                                                key={`${item.id}-${index}`}
                                                className={`m-list-timeline__item ${item.isRead ? '' : 'un-read'}`}
                                                onClick={this.onClickNotify(item)}
                                              >
                                                <span className="m-list-timeline__badge -m-list-timeline__badge--state-success" />
                                                <span className="m-list-timeline__text" dangerouslySetInnerHTML={{ __html: item.title }} />
                                                <span className="m-list-timeline__time">{formatDate(new Date(), 'YYYY-MMM-DD') === formatDate(item.createdAt, 'YYYY-MMM-DD')
                                                  ? calculatorDatetime(item.createdAt)
                                                  : formatDate(item.createdAt, 'MM/DD/YYYY h:mm A ')}
                                                </span>
                                              </a>
                                            )
                                          })
                                        )
                                      }
                                    </div>
                                    <div className=" m--margin-top-10 text-center text-bottom-notify">
                                      <a href="javascript:" onClick={this.onClickSeeAllNotifications} className="m-nav__link m--margin-top-10">
                                        <p className="content">See All Notifications</p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className=" m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light ">
                    <Dropdown className="dropdown-profile-user">
                      <Dropdown.Toggle className="clean btn-sm btn-icon btn-icon-lg">
                        <a href="javascript:" className="m-nav__link m-dropdown__toggle">
                          <span className="m-topbar__userpic">
                            <ImageThumbnail product={window.portal} image={isEmpty(userInfo.avatar) ? '/assets/img/user3.png' : userInfo.avatar} />
                          </span>
                        </a>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-0" style={{ width: '350px' }}>
                        <Dropdown.Item className="p-0">
                          <div className="m-dropdown__inner">
                            <div className="m-dropdown__header m--align-center m--padding-20" id="user_profile_icon">
                              <div className="m-card-user m-card-user--skin-dark">
                                <div className="m-card-user__pic">
                                  <ImageThumbnail product={window.portal} image={isEmpty(userInfo.avatar) ? '/assets/img/user3.png' : userInfo.avatar} />
                                </div>
                                <div className="m-card-user__details">
                                  <span className="m-card-user__name m--font-weight-500 name-profile">
                                    {userInfo.firstName}
                                    {' '}
                                    {userInfo.lastName}
                                  </span>
                                  <p className="name-email m--font-weight-300">
                                    {userInfo.emailAddress}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="m-dropdown__body m--padding-20">
                              <div className="m-dropdown__content">
                                <ul className="m-nav m-nav--skin-light">
                                  <li className="m-nav__section m--hide">
                                    <span className="m-nav__section-text">Section</span>
                                  </li>
                                  <li className="m-nav__item">
                                    <a href="javascript:" onClick={this.getProfile} className="m-nav__link">
                                      <i className="m-nav__link-icon fas fa-user-circle" />
                                      <span className="m-nav__link-title">
                                        <span className="m-nav__link-wrap">
                                          <span className="m-nav__link-text">My Profile</span>
                                        </span>
                                      </span>
                                    </a>
                                  </li>
                                  <li className="m-nav__item">
                                    <Link to="/profile?active=change-password" className="m-nav__link">
                                      <i className="m-nav__link-icon fas fa-lock" />
                                      <span className="m-nav__link-title">
                                        <span className="m-nav__link-wrap">
                                          <span className="m-nav__link-text">Update Password</span>
                                        </span>
                                      </span>
                                    </Link>
                                  </li>
                                  <li className="m-nav__item d-none">
                                    <a href="javascript:" className="m-nav__link">
                                      <i className="m-nav__link-icon fas fa-info" />
                                      <span className="m-nav__link-text">FAQ</span>
                                    </a>
                                  </li>
                                  <li className="m-nav__item d-none">
                                    <a href="javascript:" className="m-nav__link">
                                      <i className="m-nav__link-icon" />
                                      <span className="m-nav__link-text">Support</span>
                                    </a>
                                  </li>
                                  <li className="m-nav__separator m-nav__separator--fit" />
                                  <li className="m-nav__item d-none">
                                    <a href="javascript:" className="m-nav__link">
                                      <i className="m-nav__link-icon" />
                                      <span className="m-nav__link-text">Activity</span>
                                    </a>
                                  </li>
                                  <li className="m-nav__item d-none">
                                    <a href="javascript:" className="m-nav__link">
                                      <i className="m-nav__link-icon" />
                                      <span className="m-nav__link-text">Messages</span>
                                    </a>
                                  </li>
                                  <li className="m-nav__separator m-nav__separator--fit" />
                                  <li className="m-nav__item">
                                    <a href="javascript:" onClick={this.onClickLogOut} className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">Logout</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
}

export default Header
