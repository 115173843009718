import {
  call, put, takeLatest, all
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'

function* createCaseAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/cases`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })

    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_CASE_FAIL }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.CREATE_REQUEST_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_REQUEST_FAIL }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_REQUEST_FAIL }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error.data)
    }
  }
}

function* uploadFile(action) {
  try {
    const { status, data } = yield call(request.uploadFile, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/attachments`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, data)
    }
  }
}

function* getRequestSettings(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-settings`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_SETTINGS_SUCCESS, payload: data })
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.CREATE_CASE_ASYNC, createCaseAsync),
    takeLatest(types.CREATE_REQUEST_ASYNC, createRequestAsync),
    takeLatest(types.UPLOAD_ATTACHMENT_ASYNC, uploadFile),
    takeLatest(types.GET_REQUEST_SETTINGS_ASYNC, getRequestSettings)
  ])
}
