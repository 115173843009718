import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'

import * as commonTypes from '../common/types'

function* signUpAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/public/sign-up`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield [
          put({ type: types.SIGN_UP_SUCCESS, payload: data })
        ]
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: types.SIGN_UP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.SIGN_UP_FAIL })
    ]
    if (action.callback) {
      if (error && error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error)
      }
    }
  }
}

function* signInVerifyTokenAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/public/verify`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.SIGN_IN_VERIFY_TOKEN_SUCCESS, payload: data })
        if (action.callback) {
          if (data) {
            action.callback(true, data)
          }
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.SIGN_IN_VERIFY_TOKEN_FAIL })
        ]
        if (action.callback) {
          if (data) {
            action.callback(false, data)
          } else {
            action.callback(false, data)
          }
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.SIGN_IN_VERIFY_TOKEN_FAIL })
    ]
    if (action.callback) {
      if (error && error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error)
      }
    }
  }
}

function* signInAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/public/sign-in`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.SIGN_IN_SUCCESS, payload: data })
        if (action.callback) {
          if (data) {
            action.callback(true, data)
          }
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.SIGN_IN_FAIL })
        ]
        if (action.callback) {
          if (data) {
            action.callback(false, data)
          } else {
            action.callback(false, data)
          }
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.SIGN_IN_FAIL })
    ]
    if (action.callback) {
      if (error && error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error)
      }
    }
  }
}
function* forgetPasswordAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, message, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/public/recovery`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.FORGET_PASSWORD_SUCCESS, payload: { data, message } })
        if (action.callback) {
          action.callback(true, data, message)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.FORGET_PASSWORD_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data, message)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.FORGET_PASSWORD_FAIL })
    ]
    if (action.callback) {
      if (error && error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error)
      }
    }
  }
}

function* getUserProfileAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/account/profile`, action.payload, { ignoreDelay: true })
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        window.TIMEZONE = data.timeZone || '"America/New_York'
        // moment.tz.setDefault(data.timeZone || '"America/New_York')
        yield put({ type: types.GET_USER_PROFILE_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.LOG_OUT })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.LOG_OUT })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateUserProfileAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/account/profile`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.UPDATE_USER_PROFILE_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getProfileMetaDataAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/account/${action.accountId}/metadata?metaKey=${action.metaKey}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_PROFILE_METADATA_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_PROFILE_METADATA_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_PROFILE_METADATA_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateProfileMetaDataAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/account/${action.accountId}/metadata?metaKey=${action.metaKey}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.UPDATE_PROFILE_METADATA_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getUrlBeforeSign(action) {
  if (action.url) {
    yield put({ type: types.GET_URL_BEFORE_SIGN_SUCCESS, payload: action.url })
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.SIGN_UP_ASYNC, signUpAsync),
    takeLatest(types.SIGN_IN_ASYNC, signInAsync),
    takeLatest(types.SIGN_IN_VERIFY_TOKEN_ASYNC, signInVerifyTokenAsync),
    takeLatest(types.FORGET_PASSWORD_ASYNC, forgetPasswordAsync),
    takeLatest(types.GET_USER_PROFILE_ASYNC, getUserProfileAsync),
    takeLatest(types.UPDATE_USER_PROFILE_ASYNC, updateUserProfileAsync),
    takeEvery(types.GET_PROFILE_METADATA_ASYNC, getProfileMetaDataAsync),
    takeLatest(types.UPDATE_PROFILE_METADATA_ASYNC, updateProfileMetaDataAsync),
    takeLatest(types.GET_URL_BEFORE_SIGN_ASYNC, getUrlBeforeSign)
  ])
}
