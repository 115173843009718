import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

class PublicRoute extends React.Component {
  /* eslint-disable */
  static propTypes = {
    location: PropTypes.object,
    component: PropTypes.any,
    authed: PropTypes.bool
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { component: Component, ...rest } = this.props
    return [
      <Route
        {...rest}
        key="publicRoute"
        render={(props) => {
          return <Component {...props} {...rest} />
        }}
      />
    ]
  }
}

export default PublicRoute
