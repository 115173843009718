export const GET_LIST_CASE_ASYNC = 'GET_LIST_CASE_ASYNC'
export const GET_LIST_CASE_SUCCESS = 'GET_LIST_CASE_SUCCESS'
export const GET_LIST_CASE_FAIL = 'GET_LIST_CASE_FAIL'

export const GET_CASE_DETAIL_ASYNC = 'GET_CASE_DETAIL_ASYNC'
export const GET_CASE_DETAIL_SUCCESS = 'GET_CASE_DETAIL_SUCCESS'
export const GET_CASE_DETAIL_FAIL = 'GET_CASE_DETAIL_FAIL'

export const UPDATE_CASE_ASYNC = 'UPDATE_CASE_ASYNC'
export const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS'
export const UPDATE_CASE_FAIL = 'UPDATE_CASE_FAIL'

export const GET_PARTNERS_ASYNC = 'GET_PARTNERS_ASYNC'
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
export const GET_PARTNERS_FAIL = 'GET_PARTNERS_FAIL'

export const GET_CASE_REPORT_ASYNC = 'GET_CASE_REPORT_ASYNC'
export const GET_CASE_REPORT_SUCCESS = 'GET_CASE_REPORT_SUCCESS'
export const GET_CASE_REPORT_FAIL = 'GET_CASE_REPORT_FAIL'

export const GET_ACTIVITY_ASYNC = 'GET_ACTIVITY_ASYNC'
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS'
export const GET_ACTIVITY_FAIL = 'GET_ACTIVITY_FAIL'

export const CREATE_ACTIVITY_ASYNC = 'CREATE_ACTIVITY_ASYNC'
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS'
export const CREATE_ACTIVITY_FAIL = 'CREATE_ACTIVITY_FAIL'

export const DELETE_ACTIVITY_ASYNC = 'DELETE_ACTIVITY_ASYNC'
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS'
export const DELETE_ACTIVITY_FAIL = 'DELETE_ACTIVITY_FAIL'

export const CREATE_CASE_MANAGE_ASYNC = 'CREATE_CASE_MANAGE_ASYNC'
export const CREATE_CASE_MANAGE_SUCCESS = 'CREATE_CASE_MANAGE_SUCCESS'
export const CREATE_CASE_MANAGE_FAIL = 'CREATE_CASE_MANAGE_FAIL'

export const CREATE_DISCUSSION_ASYNC = 'CREATE_DISCUSSION_ASYNC'
export const CREATE_DISCUSSION_SUCCESS = 'CREATE_DISCUSSION_SUCCESS'
export const CREATE_DISCUSSION_FAIL = 'CREATE_DISCUSSION_FAIL'

export const APPEND_DISCUSSION_ASYNC = 'APPEND_DISCUSSION_ASYNC'
export const APPEND_DISCUSSION_SUCCESS = 'APPEND_DISCUSSION_SUCCESS'
export const APPEND_DISCUSSION_FAIL = 'APPEND_DISCUSSION_FAIL'

export const GET_DISCUSSION_ASYNC = 'GET_DISCUSSION_ASYNC'
export const GET_DISCUSSION_SUCCESS = 'GET_DISCUSSION_SUCCESS'
export const GET_DISCUSSION_FAIL = 'GET_DISCUSSION_FAIL'

export const GET_CASE_METADATA = 'GET_CASE_METADATA'
export const GET_DATES_SUCCESS = 'GET_DATES_SUCCESS'
export const GET_DATES_FAIL = 'GET_DATES_FAIL'

export const GET_END_DATES_ASYNC = 'GET_END_DATES_ASYNC'
export const GET_END_DATES_SUCCESS = 'GET_END_DATES_SUCCESS'
export const GET_END_DATES_FAIL = 'GET_END_DATES_FAIL'

export const UPDATE_CASE_METADATA = 'UPDATE_CASE_METADATA'
export const UPDATE_DATES_SUCCESS = 'UPDATE_DATES_SUCCESS'
export const UPDATE_DATES_FAIL = 'UPDATE_DATES_FAIL'

export const GET_ASSIGN_CASE_ASYNC = 'GET_ASSIGN_CASE_ASYNC'
export const GET_ASSIGN_CASE_SUCCESS = 'GET_ASSIGN_CASE_SUCCESS'
export const GET_ASSIGN_CASE_FAIL = 'GET_ASSIGN_CASE_FAIL'

export const CREATE_ASSIGN_CASE_ASYNC = 'CREATE_ASSIGN_CASE_ASYNC'
export const CREATE_ASSIGN_CASE_SUCCESS = 'CREATE_ASSIGN_CASE_SUCCESS'
export const CREATE_ASSIGN_CASE_FAIL = 'CREATE_ASSIGN_CASE_FAIL'

export const DELETE_ASSIGN_CASE_ASYNC = 'DELETE_ASSIGN_CASE_ASYNC'
export const DELETE_ASSIGN_CASE_SUCCESS = 'DELETE_ASSIGN_CASE_SUCCESS'
export const DELETE_ASSIGN_CASE_FAIL = 'DELETE_ASSIGN_CASE_FAIL'

export const GET_LIST_CASE_READINESS_ASYNC = 'GET_LIST_CASE_READINESS_ASYNC'
export const UPDATE_LIST_CASE_READINESS_ASYNC = 'UPDATE_LIST_CASE_READINESS_ASYNC'

export const GET_ATTACHMENT_CASE_ASYNC = 'GET_ATTACHMENT_CASE_ASYNC'
export const CREATE_ATTACHMENT_CASE_ASYNC = 'CREATE_ATTACHMENT_CASE_ASYNC'

