import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './type'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'
import { objectToParams } from '../../helpers/utils'

function* getListCaseAutomationAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases?${objectToParams(action.payload)}`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_LIST_CASE_AUTOMATION_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_LIST_CASE_AUTOMATION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data, meta)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_LIST_CASE_AUTOMATION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getCaseDetailAutomationAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases?${objectToParams(action.payload)}`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
        if (action.callback) {
          action.callback(false, data, meta)
        }
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* generateAffidavitAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* selectAttachmentAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/files/${action.fileId}/metadata`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.GET_LIST_CASE_AUTOMATION_ASYNC, getListCaseAutomationAsync),
    takeEvery(types.GET_CASE_DETAIL_AUTOMATION_ASYNC, getCaseDetailAutomationAsync),
    takeLatest(types.AFFIDAVIT_GENERATE_ASYNC, generateAffidavitAsync),
    takeEvery(types.SELECT_ATTACHMENT_ASYNC, selectAttachmentAsync)
  ])
}
