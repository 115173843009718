import {
  call, put, takeLatest, all
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import { objectToParams } from '../../helpers/utils'

function* getNotificationsAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/notifications?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_NOTIFICATIONS_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_NOTIFICATIONS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_NOTIFICATIONS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}
function* updateNotifyAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/notifications/${action.payload.id}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_NOTIFICATIONS_ASYNC, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.UPDATE_NOTIFICATIONS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.UPDATE_NOTIFICATIONS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.GET_NOTIFICATIONS_ASYNC, getNotificationsAsync),
    takeLatest(types.UPDATE_NOTIFICATIONS_ASYNC, updateNotifyAsync)
  ])
}
