import React, { Component } from 'react'
import './imageThumbnail.scss'

class ImageThumbnail extends Component {
  static defaultProps = {
    height: 50,
    width: 50,
    product: 'attorney',
    alt: ''
  }

  render() {
    const {
      image, height, width, product, alt, title
    } = this.props
    let style = {}
    if (product === 'attorney') {
      style = {
        border: '2px solid #fd7e14',
        padding: 2
      }
    } else if (product === 'pharmacy') {
      style = {
        border: '2px solid #007bff',
        padding: 2
      }
    } else if (product === 'client' || product === 'patient') {
      style = {
        border: '2px solid #34bfa3',
        padding: 2
      }
    } else if (product === 'employee') {
      style = {
        border: '2px solid #6f42c1',
        padding: 2
      }
    }
    return (
      <img style={style} title={title} className="avatar-thumbnail" src={image || '/assets/img/user3.png'} width={width} height={height} alt={alt} />
    )
  }
}
export default ImageThumbnail
