import * as types from './types'

const initialState = {
  patients: {
    data: [],
    meta: {}
  },
  patientDetail: {}
}

export default function patient(state = initialState, action) {
  switch (action.type) {
    case types.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload
      }
    // case types.GET_PATIENTS_ASYNC:
    case types.GET_PATIENTS_FAIL:
      return {
        ...state,
        patients: {
          data: [],
          meta: {}
        }
      }

    case types.GET_PATIENT_DETAIL_ASYNC:
    case types.GET_PATIENT_DETAIL_FAIL:
      return {
        ...state,
        patientDetail: {}
      }
    case types.GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        patientDetail: action.payload
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
