import * as types from './types'

const initialState = {
  requestList: {
    data: [],
    meta: {}
  },
  requestDetail: {
    attachments: []
  },
  receiverCanCloseDetail: {},
  discussion: { data: [], meta: {}, lastUpdated: new Date().getTime() },
  relatedRequestList: {
    data: [],
    meta: {}
  },
  report: {
    'approved-lop': 0,
    'reduction-approved': 0,
    'filled-pending-lop-approval': 0,
    'on-hold': 0,
    dropped: 0,
    declined: 0,
    'waiting-payment': 0,
    paid: 0,
    'waiting-for-triage': 0
  },
  requestLogs: {
    data: [],
    meta: {}
  }
}

export default function patient(state = initialState, action) {
  switch (action.type) {
    case types.GET_TRACK_REQUEST_BY_CASE_SUCCESS:
    case types.GET_TRACK_REQUEST_SUCCESS:
      return {
        ...state,
        requestList: action.payload
      }
    case types.GET_TRACK_REQUEST_ASYNC:
      return {
        ...state,
        requestDetail: {
          attachments: []
        },
        discussion: { data: [], meta: {}, lastUpdated: new Date().getTime() }
      }
    case types.GET_TRACK_REQUEST_BY_CASE_ASYNC:
    case types.GET_TRACK_REQUEST_FAIL:
      return {
        ...state,
        requestList: {
          data: [],
          meta: {}
        }
      }

    case types.GET_TRACK_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        requestDetail: {
          ...action.payload,
          attachments: state.requestDetail.attachments
        }
      }
    // case types.GET_TRACK_REQUEST_DETAIL_ASYNC:
    case types.GET_TRACK_REQUEST_DETAIL_FAIL:
      return {
        ...state,
        requestDetail: {
          attachments: []
        },
        discussion: { data: [], meta: {}, lastUpdated: new Date().getTime() }
      }
    case types.GET_TRACK_REQUEST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        requestDetail: {
          ...state.requestDetail,
          attachments: action.payload
        }
      }
    case types.GET_TRACK_REQUEST_ATTACHMENTS_ASYNC:
    case types.GET_TRACK_REQUEST_ATTACHMENTS_FAIL:
      return {
        ...state,
        requestDetail: {
          ...state.requestDetail,
          attachments: []
        }
      }

    case types.GET_DISCUSSION_SUCCESS:
      return {
        ...state,
        discussion: action.payload
      }
    case types.GET_RECEIVER_REQUEST_SUCCESS:
      return {
        ...state,
        receiverCanCloseDetail: action.payload
      }
    case types.GET_DISCUSSION_ASYNC:
    case types.GET_DISCUSSION_FAIL:
      return {
        ...state,
        discussion: []
      }

    case types.CREATE_DISCUSSION_SUCCESS:
      return {
        ...state,
        discussion: [
          ...state.discussion,
          { ...action.payload }
        ]
      }
    case types.APPEND_DISCUSSION_SUCCESS:
      return {
        ...state,
        discussion: {
          ...action.payload,
          lastUpdated: new Date().getTime()
        }
      }
    case types.GET_RELATED_REQUEST_SUCCESS:
      return {
        ...state,
        relatedRequestList: {
          ...action.payload,
          lastUpdated: new Date().getTime()
        }
      }
    case types.GET_RELATED_REQUEST_FAIL:
      return {
        ...state,
        relatedRequestList: state.relatedRequestList
      }
    case types.GET_TRACK_REQUEST_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload
        }
      }
    case types.GET_REQUEST_LOGS_SUCCESS:
      return {
        ...state,
        requestLogs: action.payload
      }
    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
