import { createStore, applyMiddleware, compose } from 'redux'
import { autoRehydrate } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducer.config'
import rootSaga from './sagas'
import { jwtMiddleware } from './jwtMiddleWare'

const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware()
  let store = {}
  if (process.env.NODE_ENV === 'development') {
    const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? compose(
        autoRehydrate(),
        applyMiddleware(jwtMiddleware, sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
      ) : compose(autoRehydrate(), applyMiddleware(jwtMiddleware, sagaMiddleware))
    store = createStore(
      rootReducer,
      initialState,
      composeEnhancers
    )
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        autoRehydrate(),
        applyMiddleware(jwtMiddleware, sagaMiddleware)
      )
    )
  }
  sagaMiddleware.run(rootSaga)
  return store
}

const store = configureStore()
export default store
