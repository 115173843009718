export const GET_CLIENTS_ASYNC = 'GET_CLIENTS_ASYNC'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL'

export const GET_CLIENT_DETAIL_ASYNC = 'GET_CLIENT_DETAIL_ASYNC'
export const GET_CLIENT_DETAIL_SUCCESS = 'GET_CLIENT_DETAIL_SUCCESS'
export const GET_CLIENT_DETAIL_FAIL = 'GET_CLIENT_DETAIL_FAIL'

export const CREATE_CLIENT_ASYNC = 'CREATE_CLIENT_ASYNC'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL'

export const EDIT_CLIENT_ASYNC = 'EDIT_CLIENT_ASYNC'
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS'
export const EDIT_CLIENT_FAIL = 'EDIT_CLIENT_FAIL'

export const IMPORT_CLIENT_ASYNC = 'IMPORT_CLIENT_ASYNC'
