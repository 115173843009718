import {
  call, takeLatest, all, put, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as types from './types'
import { objectToParams } from '../../helpers/utils'

function* uploadFile(action) {
  try {
    const { status, data } = yield call(request.uploadFile, 'POST', `${LOPRX_BASE_URL}/${version}/business/files?labels=${action.fileType}`, action.payload.params)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* editFile(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/files/${action.fileId}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteFile(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/files/${action.fileId}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* sendFileToEmail(action) {
  try {
    const { status, data, message } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, message)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestTypesAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-types?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_TYPES_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_TYPES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_TYPES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestStatusesAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-statuses?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_STATUSES_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_STATUSES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_STATUSES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestStatusesAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/request-statuses`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }
      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* getRequestResolutionsAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-resolutions?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_RESOLUTIONS_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_RESOLUTIONS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_RESOLUTIONS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestResolutionsAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/request-resolutions/${action.id}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }
      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* getRequestHashtagsAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-hashtags`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_HASHTAGS_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_HASHTAGS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_HASHTAGS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.UPLOAD_FILE_ASYNC, uploadFile),
    takeLatest(types.EDIT_FILE_ASYNC, editFile),
    takeLatest(types.DELETE_FILE_ASYNC, deleteFile),
    takeLatest(types.SEND_EMAIL_ASYNC, sendFileToEmail),
    takeLatest(types.GET_REQUEST_TYPES_ASYNC, getRequestTypesAsync),
    takeLatest(types.GET_REQUEST_STATUSES_ASYNC, getRequestStatusesAsync),
    takeLatest(types.UPDATE_REQUEST_STATUSES_ASYNC, updateRequestStatusesAsync),
    takeLatest(types.GET_REQUEST_RESOLUTIONS_ASYNC, getRequestResolutionsAsync),
    takeLatest(types.UPDATE_REQUEST_RESOLUTIONS_ASYNC, updateRequestResolutionsAsync),
    takeLatest(types.GET_REQUEST_HASHTAGS_ASYNC, getRequestHashtagsAsync)
  ])
}
