import * as types from './types'

const initialState = {
  resolutionsReport: [],
  requestReport: [],
  report: {}
}

export default function caseReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_RESOLUTION_REPORT_SUCCESS:
      return {
        ...state,
        resolutionsReport: action.payload
      }
    case types.GET_REQUEST_REPORT_SUCCESS:
      return {
        ...state,
        requestReport: action.payload
      }
    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}
