import socketIOClient from 'socket.io-client'
import sailsIOClient from 'sails.io.js'
import { SOCKET_URL, portals } from './constants'
import store from '../configs/store.config'

function getAccessToken() {
  const { accountsReducer: { userInfo } } = store.getState()
  if (userInfo && userInfo.accessToken) {
    return userInfo.accessToken
  }
  return ''
}

const io = sailsIOClient(socketIOClient)
io.sails.autoConnect = false
io.sails.url = SOCKET_URL
io.sails.reconnection = true
// io.sails.initialConnectionHeaders = { nosession: true }

let socket = null

const socketIO = {
  connect(accessToken) {
    if (accessToken) {
      io.sails.headers = { authorization: accessToken }
      socket = io.sails.connect()
    } else {
      io.sails.headers = { authorization: getAccessToken() }
      socket = io.sails.connect()
    }
  },
  subscribe(key, callback) {
    socket.on(key, (body) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`subscribe ${key}`, body)
      }
      if (callback) {
        callback(body)
      }
    })
    return io
  },
  unsubscribe(key) {
    // this will be invoked when the saga calls `channel.close` method
    socket.off(key)
  },
  socketIOGet(url, callback) {
    socket.get(url, (body, JWR) => {
      // console.log('begin socket get', JWR.body)
      // body === JWR.body
      // console.log('with headers: ', JWR.headers)
      // console.log('and with status code: ', JWR.statusCode)
      if (JWR.statusCode === 200 && callback) {
        callback(JWR.body)
      }
    })
  },
  socketIOPost(url, data, callback) {
    socket.post(url, data, (body, JWR) => {
      // // body === JWR.body
      // console.log('Sails responded with: ', body)
      // console.log('with headers: ', JWR.headers)
      // console.log('and with status code: ', JWR.statusCode)
      if (JWR.statusCode === 200 && callback) {
        callback(body)
      }
    })
  },
  disconnect(accessToken, callback) {
    if (accessToken) {
      io.sails.headers = { authorization: accessToken }
    } else {
      io.sails.headers = { authorization: getAccessToken() }
    }
    // io.sails.disconnect()
    socket.disconnect()
    if (callback) {
      callback()
    }
  }
}

export default socketIO
