import * as types from './types'

const initialState = {
  partners: {
    data: [],
    meta: {}
  },
  partnerDetail: {}
}

export default function partner(state = initialState, action) {
  switch (action.type) {
    case types.GET_PARTNER_DETAIL_ASYNC:
    case types.GET_PARTNER_DETAIL_FAIL:
      return {
        ...state,
        partnerDetail: {}
      }
    case types.GET_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: action.payload
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
