import request from './request'
import socketIO from './socketIO'
import {
  LOPRX_BASE_URL,
  LOPRX_HOMEPAGE_URL,
  LOPRX_PHARMACY_URL,
  LOPRX_ATTORNEY_URL,
  LOPRX_CAPTCHA_SITE_KEY,
  version,
  portals
} from './constants'

export {
  request,
  socketIO,
  LOPRX_BASE_URL,
  LOPRX_HOMEPAGE_URL,
  LOPRX_PHARMACY_URL,
  LOPRX_ATTORNEY_URL,
  LOPRX_CAPTCHA_SITE_KEY,
  version,
  portals
}
