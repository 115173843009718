export const GET_PARTNERS_ASYNC = 'GET_PARTNERS_ASYNC'
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
export const GET_PARTNERS_FAIL = 'GET_PARTNERS_FAIL'

export const GET_PARTNER_DETAIL_ASYNC = 'GET_PARTNER_DETAIL_ASYNC'
export const GET_PARTNER_DETAIL_SUCCESS = 'GET_PARTNER_DETAIL_SUCCESS'
export const GET_PARTNER_DETAIL_FAIL = 'GET_PARTNER_DETAIL_FAIL'

export const GET_REQUEST_ASYNC = 'GET_REQUEST_ASYNC'
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS'
export const GET_REQUEST_FAIL = 'GET_REQUEST_FAIL'

export const GET_RECEIVED_REQUEST_ASYNC = 'GET_RECEIVED_REQUEST_ASYNC'
export const GET_RECEIVED_REQUEST_SUCCESS = 'GET_RECEIVED_REQUEST_SUCCESS'
export const GET_RECEIVED_REQUEST_FAIL = 'GET_RECEIVED_REQUEST_FAIL'

export const GET_SENT_REQUEST_ASYNC = 'GET_SENT_REQUEST_ASYNC'
export const GET_SENT_REQUEST_SUCCESS = 'GET_SENT_REQUEST_SUCCESS'
export const GET_SENT_REQUEST_FAIL = 'GET_SENT_REQUEST_FAIL'

export const GET_PUBLIC_PARTNER_ASYNC = 'GET_PUBLIC_PARTNER_ASYNC'
export const GET_PUBLIC_PARTNER_SUCCESS = 'GET_PUBLIC_PARTNER_SUCCESS'
export const GET_PUBLIC_PARTNER_FAIL = 'GET_PUBLIC_PARTNER_FAIL'

export const SEND_REQUEST_ASYNC = 'SEND_REQUEST_ASYNC'
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS'
export const SEND_REQUEST_FAIL = 'SEND_REQUEST_FAIL'

export const CANCEL_REQUEST_ASYNC = 'CANCEL_REQUEST_ASYNC'
export const CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS'
export const CANCEL_REQUEST_FAIL = 'CANCEL_REQUEST_FAIL'

export const UPDATE_REQUEST_ASYNC = 'UPDATE_REQUEST_ASYNC'
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS'
export const UPDATE_REQUEST_FAIL = 'UPDATE_REQUEST_FAIL'
