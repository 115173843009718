import * as types from './types'

const initialState = {
  clients: {
    data: [],
    meta: {}
  },
  clientDetail: {}
}

export default function clients(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload
      }
    // case types.GET_CLIENTS_ASYNC:
    case types.GET_CLIENTS_FAIL:
      return {
        ...state,
        clients: {
          data: [],
          meta: {}
        }
      }

    case types.GET_CLIENT_DETAIL_ASYNC:
    case types.GET_CLIENT_DETAIL_FAIL:
      return {
        ...state,
        clientDetail: {}
      }
    case types.GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        clientDetail: action.payload
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
