import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loading from '../loading'

class Layout extends Component {
  static propTypes = {
    children: PropTypes.any
  }

  render() {
    const { children } = this.props
    return (
      <div className="m-grid m-grid--hor m-grid--root m-page">
        <Loading />
        {children}
      </div>
    )
  }
}

export default Layout
